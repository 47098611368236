import React, { useState, useEffect } from 'react';
import styles from "./BloodReportPreview5.module.css";

const BloodReportLabTestResult = ({ reportData1: reportData }) => {
  // Initialize state with reportData
  const [data, setData] = useState(reportData);

  // Use useEffect to update state when reportData changes
  useEffect(() => {
    if (reportData) {
      setData(reportData);
    }
  }, [reportData]); // Re-run effect when reportData changes

  return (
    <div className="tw-p-[20px]">
      <div className={styles.header}>
        <div className={styles.title}>{data?.title || '03 Lab Test Results'}</div>
        <div className={styles.patient_info}>
          {data?.name || 'name'} | Patient ID: {data?.patientId || 'patientID'}
        </div>
      </div>
      
      <div className={styles.note}>
        Note:
        <br />
        {data?.note || 'This is an electronically generated summary of the attached report. It is advised to read this summary in conjunction with the attached report and to correlate it clinically. For the trends section, the out of range values are highlighted with respect to the bio reference range of respective reports'}
      </div>

      <table>
        <thead>
          <tr>
            <th rowSpan="2" className={styles.merged_header}>Test Name</th>
            <th rowSpan="2" className={styles.merged_header}>Date of Test</th>
            <th rowSpan="2" className={styles.merged_header}>Bio. Ref. Interval</th>
            <th colSpan="3" className={styles.trends_header}>Trends (For last three tests)</th>
          </tr>
          <tr>
            <th>Date 1</th>
            <th>Date 2</th>
            <th>Date 3</th>
          </tr>
        </thead>
        <tbody>
          {data?.completeReport?.map((category, categoryIndex) => (
            <React.Fragment key={categoryIndex}>
              <tr className={styles.test_category}>
                <td style={{ fontSize: "20px" }} colSpan="6">{category.category}</td>
              </tr>
              {category.results.map((test, testIndex) => (
                <tr key={testIndex}>
                  <td>{test.testName}</td>
                  <td>{test.latestResult}</td>
                  <td>{test.minRange} - {test.maxRange} {test.unit}</td>
                  {/* {test.trends.map((trend, trendIndex) => (
                    <td key={trendIndex}>{trend}</td>
                  ))} */}
                </tr>
              ))}
            </React.Fragment>
          )) || (
            <tr>
              <td colSpan="6">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BloodReportLabTestResult;