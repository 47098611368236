import React, { useState } from 'react';
import './PushNotifications.css';
import { Link } from 'react-router-dom';
import PushNotificationsTable from './component/PushNotificationsTable';


function PushNotifications() {


  return (
    <>
      <section className='PushNotifications'>
        <div className="container-fluid px-0">
          {/* <div className="row align-items-center pb-3">
            <div className="col-md">
              <h1 className="main-title mb-0">Corporate Push Notification</h1>
            </div>
            <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-push-notifications">Create Push Notification</Link></div>
          </div> */}

          {/* <div className='push-notifications mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/97822_1726724437.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo. Vivamus et interdum felis, ut vulputate tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo.</p>
              </div>
            </div>
          </div> */}

          {/* <div className='push-notifications'> */}
            
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive tablePushNotifications">
                   <PushNotificationsTable/>
                   </div>
                </div>
            </div>

          {/* </div> */}
        </div>
      </section>
    </>
  );
}

export default PushNotifications;
