import React from 'react';
import './CorporatesManageDetails.css';


const CorporatesManageDetails = ({ totalMembers, totalGroups, totalChallenges }) => {
  const statsCorpManageDetails = [
    { count: totalMembers, label: 'Total Members', bgColor: 'bg-skyblue' },
    { count: totalGroups, label: 'Total Groups', bgColor: 'bg-red' },
    { count: totalChallenges, label: 'Total Challenges', bgColor: 'bg-purple' },
  ];

  return (
    <>
    <section className='CorporatesManageDetails'>
        <div className=" row text-center">
        {statsCorpManageDetails.map((stat, index) => (
                <div className="col ps-0" key={index}>
                    <div className={`card ${stat.bgColor}`} style={{ borderRadius: '14px', border: 'none' }}>
                        <div className="card-body d-flex justify-content-center">
                        <div className="row align-items-center">
                            <div className="col text-center">
                                <h1 className="card-title">{stat.count}</h1>
                                <h5 className="card-text">{stat.label}</h5>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </section>
    </>
    
  );
};

export default CorporatesManageDetails;
