import React, { useState, useRef, useEffect } from 'react';
import styles from "./BloodReportPreview1.module.css";

const BloodReportIntro = ({ reportData1:reportData }) => {
    // Initialize state with reportData1
    const [data, setData] = useState(reportData);

    // Use useEffect to update state when reportData1 (reportData) changes
    useEffect(() => {
      if (reportData) {
        setData(reportData);
      }
    }, [reportData]); // Re-run effect when reportData changes
  
  return (
    <>
      <body>
        <div className={styles.container}>
          <img
            src="https://appcdn.goqii.com/storeimg/81065_1726638137.jpg"
            alt=""
            className={styles.background_image}
          />
          <div className={styles.content}>
            <div className={styles.left_column}>
              <img
                src="https://appcdn.goqii.com/storeimg/11129_1725520285.jpg"
                alt=""
                className={styles.logo}
              />
              <div>
                <p className={`${styles.title} ${styles.orange_text}`}>A Comprehensive</p>
                <p className={`${styles.title_2} ${styles.teal_text}`}>Personal Health</p>
                <p className={`${styles.title_2} ${styles.teal_text}`}>Smart Report</p>
                <p style={{ marginTop: "6px" }} className={styles.subtitle}>
                  AI Based Personalized
                </p>
                <p className={styles.subtitle}>Report for You</p>
              </div>
              <p className={styles.description}>
                A Comprehensive analysis of your health using Blood, Physical
                and Health Questionnaire data
              </p>
            </div>
            <div className={styles.right_column}>
              <img
                src="https://appcdn.goqii.com/storeimg/97157_1725520168.jpg"
                alt=""
              />
            </div>
          </div>
          <div className={styles.patient_info}>
            <div className={styles.patient_details}>
              <h4 className={styles.patient_id}>Patient ID: {data?.patientId || '12345'}</h4>
              <h2 className={styles.patient_name}>{data.name}</h2>
              <h4 className={styles.patient_meta}>
                {data.gender} / {data.age} Yrs
              </h4>
            </div>
            <div className={styles.report_dates}>
              <div>
                <h6>Report released on</h6>
                <h4>{data.reportDate}</h4>
              </div>
              <div>
                <h6>Date of Test</h6>
                <h4>{data.dateOfTest}</h4>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default BloodReportIntro;