import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import HeaderDesc from './HeaderDesc';
import { FaClone, FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { fetchAllBlogs, fetchSelectedBlog } from "../../../services/cms/Service";
import { useNavigate } from 'react-router-dom';

const ChooseTemplate = ({ onTemplateChosen, onBack }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [existingTemplates, setExistingTemplates] = useState(null);
  console.log(existingTemplates, "existingTemplates")
  console.log(selectedTemplate, "selectedTemplate")
  const navigate = useNavigate();
  // const existingTemplates = [
  //   { value: 'template1', label: 'Health and Wellness Blog' },
  //   { value: 'template2', label: 'Fitness Tips Blog' },
  //   { value: 'template3', label: 'Nutrition Guide Blog' },
  // ];

  const handleChooseTemplate = () => {
    if (selectedTemplate) {
      const templateContent = `This is the content of ${selectedTemplate.label}`;
      // onTemplateChosen(templateContent);
      fetch_Selected_Blog(selectedTemplate.value)
      navigate(`/content-management/edit-post/${selectedTemplate.value}`)
    }
  };
  async function fetch_All_Blogs() {
    try {
      let response = await fetchAllBlogs();
      if (response) {
        const formattedTemplates = response.data.data.blogs.map((blog) => ({
          value: blog.ID, // Assuming `id` as a unique identifier
          label: blog.post_title, // Use `post_title` for the label
        }));
        setExistingTemplates(formattedTemplates);
      } else {
        throw new Error("Failed to fetch blogs");
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function fetch_Selected_Blog(id) {
    try {
      if (selectedTemplate) {
        let response = await fetchSelectedBlog({ id: id });
        if (response) {
          console.log(response, "response")
        } else {
          throw new Error("Failed to fetch blogs");
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetch_All_Blogs()
  }, []);

  return (
    <>
      <div className="tw-p-6 tw-mt-8 tw-bg-white tw-rounded-lg tw-shadow-xl tw-max-w-4xl tw-mx-auto">
        <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-text-gray-900 tw-flex tw-items-center">
          <FaClone className="tw-mr-3" /> Choose Existing Blog
        </h2>

        <div className="tw-bg-gray-100 tw-shadow-xl hover:tw-shadow-2xl tw-transition-shadow tw-duration-300">
          <div className="tw-p-6 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow">
            <p className="tw-text-lg tw-mb-4">Select a blog from the library  to jumpstart your blog creation:</p>
            <Select
              options={existingTemplates}
              onChange={setSelectedTemplate}
              placeholder="Select a Blog"
              className="tw-mb-6"
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: '#d1d5db',
                  '&:hover': { borderColor: '#9ca3af' },
                }),
              }}
            />
            <div className="tw-flex tw-flex-row tw-justify-between">
              <button
                className="tw-flex tw-items-center tw-border-2 tw-text-lg tw-border-black tw-px-8 tw-py-3 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
                onClick={onBack}
              >
                <FaArrowLeft className="tw-mr-2" /> Back
              </button>
              <button
                className="tw-flex tw-items-center hover:tw-scale-105 tw-transition-all tw-duration-300 tw-px-8 tw-py-3 tw-me-2 tw-mb-2 tw-text-lg tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border-2 tw-border-gray-300 hover:tw-bg-gray-300 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100"
                onClick={handleChooseTemplate}
                disabled={!selectedTemplate}
              >
                Use Blog <FaArrowRight className="tw-ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseTemplate;