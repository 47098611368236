import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';


const DemographicsAgeGroup = ({ ag20to30, ag30to40, ag40to50, agabove50, }) => {

    const statsDemographicsAgeGroup1 = [
        { 
            count: ag20to30+'%', 
            label: '20-30 years', 
            bgColor: 'bgLightBlue', 
            corpIcon: faUserGroup, 
        },
        { 
            count: ag30to40+'%', 
            label: '30 - 40 years', 
            bgColor: 'bgLightGreen', 
            corpIcon: faUserGroup, 
        },
    ];

    const statsDemographicsAgeGroup2 = [
        { 
            count: ag40to50+'%', 
            label: '40 - 50 years', 
            bgColor: 'bgLightOrange', 
            corpIcon: faUserGroup, 
        },
        { 
            count: agabove50+'%', 
            label: '50+ years', 
            bgColor: 'bgLightPurple', 
            corpIcon: faUserGroup, 
        },
    ];

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md">
            <ul className="list-group list-group-flush">
                {statsDemographicsAgeGroup1.map((stat, index) => (
                    <li className="list-group-item px-0" key={index}>
                    <div className="row align-items-center">
                        <div className="col-5 pe-0">
                        <h2 className="corporate-detail-subtitle">
                        <FontAwesomeIcon icon={stat.corpIcon} className="css-DFA-Icon me-1" /> {stat.label}
                        </h2>
                        </div>
                        <div className="col-1 px-0">
                        <h2 className="corporate-detail-subtitle text-end">
                            <span>{stat.count}</span>
                        </h2>
                        </div>
                        <div className="col-6">
                            <div className="progress-bar-container">
                                <div class="progress">
                                    <div className={`progress-bar ${stat.bgColor}`} role="progressbar" style={{ width: stat.count }} ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </li>
                    ))}
                </ul>
            </div>

            <div className="col-md-auto px-2">
                <div className="vr"></div>
            </div>

            <div className="col-md">
            <ul className="list-group list-group-flush">
                {statsDemographicsAgeGroup2.map((stat, index) => (
                    <li className="list-group-item px-0" key={index}>
                    <div className="row align-items-center">
                        <div className="col-5 pe-0">
                        <h2 className="corporate-detail-subtitle">
                        <FontAwesomeIcon icon={stat.corpIcon} className="css-DFA-Icon me-1" /> {stat.label}
                        </h2>
                        </div>
                        <div className="col-1 px-0">
                        <h2 className="corporate-detail-subtitle text-end">
                            <span>{stat.count}</span>
                        </h2>
                        </div>
                        <div className="col-6">
                            <div className="progress-bar-container">
                                <div class="progress">
                                    <div className={`progress-bar ${stat.bgColor}`} role="progressbar" style={{ width: stat.count }} ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </li>
                    ))}
                </ul>
            </div>
      </div>

    </>
  );
};

export default DemographicsAgeGroup;
