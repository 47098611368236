import React, { useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const FullHeightQuill = ({ value, onChange, modules, formats }) => {
    const quillRef = useRef(null);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.root.style.height = '600px';
            quill.root.style.minHeight = '640px';
        }
    }, []);

    return (
        <ReactQuill
            ref={quillRef}
            theme="snow"
            value={value}
            onChange={onChange}
            modules={modules}
            formats={formats}
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        />
    );
};

export default FullHeightQuill;