import React, { useState } from 'react';
import { FaFileInvoiceDollar, FaChartLine, FaDatabase } from 'react-icons/fa';

const billingTools = [
  { name: 'Kareo', icon: FaFileInvoiceDollar },
  { name: 'Catalyst Billing Solutions', icon: FaChartLine },
  { name: 'Advanced Data System Corporation', icon: FaDatabase },
];

const BillingToolCard = ({ name, Icon, isActive, onClick }) => (
  <div 
    className={`tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-md tw-transition-all tw-duration-300 hover:tw-shadow-lg tw-cursor-pointer
      ${isActive ? 'tw-border-2 tw-border-[#03335b] tw-bg-[#03335b]/10' : 'tw-border tw-border-gray-200'}`}
    onClick={onClick}
  >
    <div className="tw-flex tw-items-center">
      <div className="tw-w-12 tw-h-12 tw-bg-gray-100 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-mr-4 tw-flex-shrink-0">
        <Icon className="tw-text-[#03335b] tw-text-2xl" />
      </div>
      <h3 className="tw-text-lg tw-font-semibold tw-text-[#03335b]">{name}</h3>
    </div>
  </div>
);

const BillingAndClaimsTools = () => {
  const [activeTool, setActiveTool] = useState(null);

  return (
    <div className="tw-bg-white tw-rounded-lg tw-p-8 tw-m-4 tw-shadow-lg">
      <p className="tw-text-gray-600 tw-mb-6">
        Automate the transfer of medical codes and results, improving claim accuracy and reducing denials. B2B lab payment integrations simplify billing management between labs and vendors.
      </p>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6">
        {billingTools.map((tool, index) => (
          <BillingToolCard
            key={tool.name}
            name={tool.name}
            Icon={tool.icon}
            isActive={index === activeTool}
            onClick={() => setActiveTool(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default BillingAndClaimsTools;