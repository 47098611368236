import React from 'react';

import './ManageCorporatesList.css';
import { Link } from 'react-router-dom';

function ManageCorporatesList({ corporatesData }) {
  const corporateManageCorporate = [
    {
        corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/88013_1727347472.jpg',
        corpTItleManageCorporate: 'ONGC',
        corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/79550_1727348575.jpg',
      corpTItleManageCorporate: 'Indian Oil Corporation',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/96708_1727348242.jpg',
      corpTItleManageCorporate: 'HPCL',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/18364_1727348619.jpg',
      corpTItleManageCorporate: 'Cognizant GCC',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/66567_1727348776.jpg',
      corpTItleManageCorporate: 'LinkedIN',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/35510_1727348880.jpg',
      corpTItleManageCorporate: 'Larsen & Toubro',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/45555_1727349080.jpg',
      corpTItleManageCorporate: 'Tata Motors',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/84257_1727349232.jpg',
      corpTItleManageCorporate: 'Cipla',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/91142_1727349381.jpg',
      corpTItleManageCorporate: 'Adani Wilmar',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/5567_1727349756.jpg',
      corpTItleManageCorporate: 'Motilal Oswal',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/99688_1727349857.jpg',
      corpTItleManageCorporate: 'Bajaj Allianz GCC',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/54612_1727349983.jpg',
      corpTItleManageCorporate: 'OYO Rooms',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/84257_1727349232.jpg',
      corpTItleManageCorporate: 'Cipla',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/91142_1727349381.jpg',
      corpTItleManageCorporate: 'Adani Wilmar',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/5567_1727349756.jpg',
      corpTItleManageCorporate: 'Motilal Oswal',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/99688_1727349857.jpg',
      corpTItleManageCorporate: 'Bajaj Allianz GCC',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/54612_1727349983.jpg',
      corpTItleManageCorporate: 'OYO Rooms',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/45555_1727349080.jpg',
      corpTItleManageCorporate: 'Tata Motors',
      corpUrlManageCorporate: '/corporates-detail/1111',
    },
  ];

  return (
    <>
      {corporatesData.map((statEmp, index) => (
            <div  className="corporate-profile-imageContainer col-md-2 mt-4" key={index} >
                <Link to={`/corporates-detail/${statEmp.corporateId}`}>
                    <img src={statEmp.corporateLogo} className="corporate-profile-img" alt="" />
                    <h1 className="corporate-profile-img-title mb-0"> {statEmp.corporateName} </h1>
                </Link>
            </div>
      ))}
    </>
  );
}

export default ManageCorporatesList;
