import React, { useState, useEffect } from 'react';
import { FaPencilAlt, FaClone, FaRobot, FaUpload, FaVideo, FaYoutube, FaEdit, FaEye, FaPaperPlane, FaBlog, FaTags, FaListUl, FaUserPlus, FaImage } from 'react-icons/fa';
import HeaderDesc from './HeaderDesc';
import CategoryManagement from './CategoryManagement';
import TagManagement from './TagManagement';
import CreatorManagement from './CreatorManagement';
import ImageTagging from './ImageTagging';
import BlogTab from './BlogTab';
import { Link } from 'react-router-dom';
import { all_drafted_articles, sendApproval, log_data } from "../../../services/cms/Service";
import { useNavigate } from 'react-router-dom';


const BlogCreationOptions = ({
  onSelectOption,
  categories,
  tags,
  creators,
  onAddCategory,
  onEditCategory,
  onDeleteCategory,
  onAddTag,
  onEditTag,
  onDeleteTag,
  onAddCreator,
  onEditCreator,
  onDeleteCreator,
  onAddImage,
  onEditImage,
  onDeleteImage
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [currentView, setCurrentView] = useState('main');
  const [draftedBlogArticles, setDraftedBlogArticles] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const roleId = localStorage.getItem('roleId')

  const navigate = useNavigate();
  // Dummy data for the Draft Arena
  const draftItems = [
    { id: 1, title: 'Fitness Tips for Beginners', lastUpdated: '2024-08-15', author: 'John Doe', type: 'blog', content: '<p>Starting a fitness journey can be daunting, but with these tips, you\'ll be on your way to a healthier lifestyle in no time...</p>', categories: ['Fitness', 'Health'], tags: ['beginners', 'workout tips'] },
    { id: 2, title: 'Healthy Eating Habits', lastUpdated: '2024-08-14', author: 'Jane Smith', type: 'blog', content: '<p>Developing healthy eating habits is crucial for maintaining overall wellness. Here are some strategies to improve your diet...</p>', categories: ['Nutrition', 'Health'], tags: ['diet', 'healthy living'] },
    { id: 3, title: 'Full Body Workout Routine', lastUpdated: '2024-08-13', author: 'Mike Johnson', type: 'video', videoUrl: 'https://example.com/full-body-workout.mp4', thumbnailUrl: 'https://example.com/workout-thumbnail.jpg', description: 'A comprehensive full-body workout routine suitable for all fitness levels.', categories: ['Fitness', 'Exercise'], tags: ['workout', 'fitness'] },
    // ... (add more items as needed)
  ];

  useEffect(() => {
    getAllDraftedArticles();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = draftItems.slice(indexOfFirstItem, indexOfLastItem);



  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAllDraftedArticles(pageNumber);
  };

  const handleSendForApproval = (id) => {
    console.log(id, "pid")
    send_Approval(id)
  };

  const handleItemClick = (item, action) => {
    if (action === 'preview') {
      onSelectOption('preview', item);
    } else if (action === 'edit') {
      onSelectOption(item.type === 'blog' ? 'editor' : 'videoUpload', item);
    }
  };

  async function getAllDraftedArticles(pageNo = 1) {
    try {
      let resp = await all_drafted_articles({ pageNo });

      if (resp?.data?.code === 200) {
        setDraftedBlogArticles(resp.data?.data?.articles);
        setTotalPages(Math.floor(resp.data?.data?.totalPages));
      }
    } catch (e) {

    }

  }

  async function send_Approval(id) {
    try {
      let resp = await sendApproval({ pid: id });
      console.log(resp, "response")
      log_data({ pid: id, action: "send for Approval" })
    } catch (e) {

    }

  }

  const renderMainView = () => (
    <>
      <BlogTab selectedIndex={roleId == 25 ? 0 : 1} />
      <HeaderDesc
        image={'https://blog.pcloud.com/wp-content/uploads/2022/05/Content-creation-no-text-1200x675-1.png'}
        desc={'Create blogs, videos, and shorts with tools provided. This section empowers content creators to produce engaging content tailored for various platforms, ensuring consistency and efficiency throughout the creative process.'}
      />
      <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-mt-12 tw-text-gray-800">Create New Blog</h2>

      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-6">
        {[
          { title: 'Create from Scratch', icon: FaPencilAlt, desc: 'Start with a blank canvas and create your blog post from scratch.', option: 'editor', path: "/content-management/add-post" },
          { title: 'Choose from Library', icon: FaClone, desc: 'Select from a variety of published blog to jumpstart your content.', option: 'template', path: "/content-management/test1" },
          { title: 'Create with AI', icon: FaRobot, desc: 'Let our AI assist you in generating content for your blog post.', option: 'ai', path: "/content-management/create-post-ai" }
        ].map((item, index) => (
          <Link key={index} to={item.path}>
            <div
              className="tw-bg-gray-100 tw-text-black tw-rounded-lg tw-shadow-xl hover:tw-shadow-2xl tw-transition-shadow tw-duration-300 tw-cursor-pointer"
            >
              <div className="tw-p-4">
                <h3 className="tw-text-xl tw-font-semibold tw-mb-2 tw-flex tw-items-center">
                  <item.icon className="tw-mr-2" /> {item.title}
                </h3>
                <p className="tw-text-gray-600">{item.desc}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>

      <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-mt-12 tw-text-gray-800">Create New Video</h2>

      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-6">
        {[
          { title: 'Upload a Video', icon: FaUpload, desc: 'Easily upload your own video files directly from your device to create engaging content.', option: 'videoUpload', path: '/content-management/add-video' },
          { title: 'Choose from Library', icon: FaVideo, desc: 'Access and use pre-existing videos from your library for quick content creation.', option: 'chooseVideoFromLibrary', path: '/content-management/test' },
          { title: 'Add YouTube Video', icon: FaYoutube, desc: 'Integrate YouTube videos into your project by simply adding the video link.', option: 'uploadYouTubeVideo', path: '/content-management/add-yt-video' }
        ].map((item, index) => (
          <Link to={item.path}>
            <div
              key={index}
              className="tw-bg-gray-100 tw-rounded-lg tw-shadow-xl hover:tw-shadow-2xl tw-transition-shadow tw-duration-300 tw-cursor-pointer"
            >
              <div className="tw-p-4">
                <h3 className="tw-text-xl tw-font-semibold tw-mb-2 tw-flex tw-items-center">
                  <item.icon className="tw-mr-2" /> {item.title}
                </h3>
                <p className="tw-text-gray-600">{item.desc}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* Draft Arena */}
      <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-mt-12 tw-text-gray-800">Draft Arena</h2>
      <div className="tw-overflow-x-auto tw-shadow-md tw-rounded-lg">
        <table className="tw-w-full tw-text-sm tw-text-left tw-text-gray-500">
          <thead className="tw-text-xs tw-text-white tw-uppercase tw-bg-blue-600">
            <tr>
              <th scope="col" className="tw-px-6 tw-py-3">Title</th>
              <th scope="col" className="tw-px-6 tw-py-3">Type</th>
              <th scope="col" className="tw-px-6 tw-py-3">Last Updated</th>
              <th scope="col" className="tw-px-6 tw-py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {draftedBlogArticles.map((item) => (
              <tr key={item.id} className="tw-bg-white tw-border-b hover:tw-bg-gray-50" >
                <td className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap hover:tw-underline tw-cursor-pointer" onClick={e => navigate(`/content-management/edit-post/${item.ID}`)}>
                  {item.post_title}
                </td>
                <td className="tw-px-6 tw-py-4">
                  {item.post_type === 'Blog' ? (
                    <span className="tw-flex tw-items-center tw-text-blue-600"><FaBlog className="tw-mr-2" /> Blog</span>
                  ) : (
                    <span className="tw-flex tw-items-center tw-text-red-600"><FaVideo className="tw-mr-2" /> Video</span>
                  )}
                </td>
                <td className="tw-px-6 tw-py-4">{new Date(item.post_modified).toLocaleString('en-GB', {
                  day: '2-digit',
                  month: 'short',
                  year: '2-digit',
                  timeZone: 'Asia/Kolkata'
                })}</td>
                {item.post_type === 'Blog' ? (
                  <td className="tw-px-6 tw-py-4 tw-text-xl tw-flex tw-items-center tw-justify-start gap-2">
                    <button onClick={() => navigate(`/content-management/edit-post/${item.ID}`)} className="tw-text-blue-600 hover:tw-text-blue-900 tw-mr-2 " title="Edit">
                      <FaEdit />
                    </button>
                    <button onClick={() => navigate(`/content-management/preview-post/${item.ID}`)} className="tw-text-green-600 hover:tw-text-green-900 tw-mr-2" title="Preview">
                      <FaEye />
                    </button>
                    <button onClick={() => { handleSendForApproval(item.ID) }} className="tw-text-yellow-600 hover:tw-text-yellow-900" title="Send for Approval">
                      <FaPaperPlane />
                    </button>
                  </td>)
                  : (
                    <td className="tw-px-6 tw-py-4 tw-text-xl tw-flex tw-items-center tw-justify-start gap-2">
                      <button onClick={() => navigate(`/content-management/add-video-library?id=${item.ID}`)} className="tw-text-blue-600 hover:tw-text-blue-900 tw-mr-2 " title="Edit">
                        <FaEdit />
                      </button>
                      <button onClick={() => navigate(`/content-management/preview-post/${item.ID}`)} className="tw-text-green-600 hover:tw-text-green-900 tw-mr-2" title="Preview">
                        <FaEye />
                      </button>
                      <button onClick={() => { handleSendForApproval(item.ID) }} className="tw-text-yellow-600 hover:tw-text-yellow-900" title="Send for Approval">
                        <FaPaperPlane />
                      </button>
                    </td>)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="tw-flex tw-justify-center tw-mt-4">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`tw-px-3 tw-py-1 tw-mx-1 tw-rounded ${currentPage === page
              ? 'tw-bg-blue-500 tw-text-white'
              : 'tw-bg-gray-200 tw-text-gray-700 hover:tw-bg-gray-300'
              }`}
          >
            {page}
          </button>
        ))}
      </div>

      {/* Classification Management */}
      <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-mt-12 tw-text-gray-800">Classification Management</h2>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
        <div
          onClick={() => setCurrentView('categoryManagement')}
          className="tw-bg-gray-100 tw-rounded-lg tw-shadow-xl hover:tw-shadow-2xl tw-transition-shadow tw-duration-300 tw-cursor-pointer tw-p-6"
        >
          <h3 className="tw-text-xl tw-font-semibold tw-mb-2 tw-flex tw-items-center">
            <FaListUl className="tw-mr-2" /> Manage Categories
          </h3>
          <p className="tw-text-gray-600 tw-mb-4">
            Organize your content effectively by creating, editing, and managing categories. Categorize your blogs and videos for easy navigation and improved user experience.
          </p>
          {/* <button 
      className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300"
    >
      Manage Categories
    </button> */}
        </div>
        <div
          onClick={() => setCurrentView('tagManagement')}
          className="tw-bg-gray-100 tw-rounded-lg tw-shadow-xl hover:tw-shadow-2xl tw-transition-shadow tw-duration-300 tw-cursor-pointer tw-p-6"
        >
          <h3 className="tw-text-xl tw-font-semibold tw-mb-2 tw-flex tw-items-center">
            <FaTags className="tw-mr-2" /> Manage Tags
          </h3>
          <p className="tw-text-gray-600 tw-mb-4">
            Enhance content discoverability with tags. Create and manage tags to label your content with specific topics, making it easier for users to find related articles and videos.
          </p>
          {/* <button 
      className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300"
    >
      Manage Tags
    </button> */}
        </div>
      </div>
      {/* Creator and Image Management */}
      <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-mt-12 tw-text-gray-800">Creator and Image Management</h2>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
        <div
          onClick={() => setCurrentView('creatorManagement')}
          className="tw-bg-gray-100 tw-rounded-lg tw-shadow-xl hover:tw-shadow-2xl tw-transition-shadow tw-duration-300 tw-cursor-pointer tw-p-6"
        >
          <h3 className="tw-text-xl tw-font-semibold tw-mb-2 tw-flex tw-items-center">
            <FaUserPlus className="tw-mr-2" /> Manage Creators
          </h3>
          <p className="tw-text-gray-600 tw-mb-4">
            Add, edit, and manage creators who contribute to your content. Keep track of creator information and easily tag them in your posts.
          </p>
        </div>
        <div
          onClick={() => setCurrentView('imageTagging')}
          className="tw-bg-gray-100 tw-rounded-lg tw-shadow-xl hover:tw-shadow-2xl tw-transition-shadow tw-duration-300 tw-cursor-pointer tw-p-6"
        >
          <h3 className="tw-text-xl tw-font-semibold tw-mb-2 tw-flex tw-items-center">
            <FaImage className="tw-mr-2" /> Image Tagging
          </h3>
          <p className="tw-text-gray-600 tw-mb-4">
            Upload, tag, and manage images for reuse in your content. Easily search and retrieve images based on tags.
          </p>
        </div>
      </div>
    </>
  );

  const renderView = () => {
    switch (currentView) {
      case 'categoryManagement':
        return (
          <CategoryManagement
            categories={categories}
            onAddCategory={onAddCategory}
            onEditCategory={onEditCategory}
            onDeleteCategory={onDeleteCategory}
            onBack={() => setCurrentView('main')}
            roleId={roleId}
          />
        );
      case 'tagManagement':
        return (
          <TagManagement
            tags={tags}
            onAddTag={onAddTag}
            onEditTag={onEditTag}
            onDeleteTag={onDeleteTag}
            onBack={() => setCurrentView('main')}
          />
        );
      case 'creatorManagement':
        return (
          <CreatorManagement
            creators={creators}
            onAddCreator={onAddCreator}
            onEditCreator={onEditCreator}
            onDeleteCreator={onDeleteCreator}
            onBack={() => setCurrentView('main')}
            roleId={roleId}
          />
        );
      case 'imageTagging':
        return (
          <ImageTagging
            onAddImage={onAddImage}
            onEditImage={onEditImage}
            onDeleteImage={onDeleteImage}
            onBack={() => setCurrentView('main')}
          />
        );
      default:
        return renderMainView();
    }
  };

  return (
    <div className="tw-p-4 tw-bg-white tw-rounded-lg tw-shadow-xl tw-mb-16">
      {renderView()}
    </div>
  );
};

export default BlogCreationOptions;