import React, { useState } from 'react';
import './CreateLeaderboard.css';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom";


function CreateLeaderboard() {

  const handleBackButton = () => {
    navigate('/corporate-challenges');
    }

    const navigate = useNavigate();

  return (
    <>
      <section className='CreateLeaderboard'>
        <div className="container-fluid px-0 mb-3">
        <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                display: 'flex',
                alignItems: 'center'
                }}>
                <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button>
                  <h1 className="main-title" style={{ margin: '0' }}>Create Push Notification</h1>
              </div>
              {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" data-bs-toggle="modal" data-bs-target="#testPushNotification">Test Notification</Link></div> */}

          </div>
{/* 
          <div className='create-leaderboard mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/15193_1726204545.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">This page allows administrators to design and tailor leaderboards that fit specific wellness challenges, providing a unique way to track and showcase participant or team performance.</p>
              </div>
            </div>
          </div> */}

          <div className='create-leaderboard p-4'>
            
            <div className="row">
                <div className="col-md-4 mb-4">
                    <label className="form-label sub-title">Leader Board URL</label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="http://coach.goqii.com/leaderboard/nv/corporate/"/>
                </div>

                <div className="col-md-4 mb-4">
                    <label className="form-label sub-title">Type of Challenge <span className='text-danger'>*</span></label>
                    <select className="form-select input-field-ui " id="" name="" aria-label="Default select example">
                      <option value="">Select Activity</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      </select>
                </div>

                <div className="col-md-4 mb-4">
                    <div className="row">
                    <div className="col-md-12">
                        <label className="form-label sub-title">Auto refresh Leader Board</label>
                    </div>

                    <div className="col-md-12">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                            <input type="number" className="form-control input-field-ui" id="" placeholder="000"/>
                            </div>
                            <div className="col-md-auto ps-0">
                            <label className="form-label mb-0">In Seconds</label>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div className="col-md-4 mb-4">
                    <label className="form-label sub-title">Page Title <span className='text-danger'>*</span></label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Enter Page Title"/>
                </div>

                <div className="col-md-4 mb-4">
                    <label className="form-label sub-title">Header Text <span className='text-danger'>*</span></label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Enter Header Text"/>
                </div>

                <div className="col-md-4 mb-4">
                    <label className="form-label sub-title">Sub Header Text</label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Enter Sub Header Text"/>
                </div>

                <div className="col-md-4 mb-4">
                    <label className="form-label sub-title">Scrolling Text</label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Enter Scrolling Text"/>
                </div>

                <div className="col-md-4 mb-4">
                <div className="row">
                    <div className="col-md">
                        <label className="form-label sub-title">Challenge Start Date <span className='text-danger'>*</span></label>
                        <input type="date" className="form-control input-field-ui" id="" placeholder="Start Date"/>
                    </div>
                    <div className="col-md">
                        <label className="form-label sub-title">Challenge End Date <span className='text-danger'>*</span></label>
                        <input type="date" className="form-control input-field-ui" id="" placeholder="End Date"/>
                    </div>
                </div>
              </div>
              

            </div>

            <div className="row">
                <div className="col-md-4 mb-4">
                  <div className='row'>
                    <label className="form-label sub-title">Top LHS Logo <span className='text-danger'>*</span></label>
                    <div className='col pe-1'>
                      <input type="file" className="form-control input-field-ui" id="" placeholder="Enter Challenge Title"/>
                    </div>
                    <div className='col-auto ps-0'>
                      <button type="button" id="xlsxSubmit" className="btn btn-success uplaod-file-btn">Upload File</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className='row'>
                    <label className="form-label sub-title">Top RHS Logo <span className='text-danger'>*</span></label>
                    <div className='col pe-1'>
                      <input type="file" className="form-control input-field-ui" id="" placeholder="Enter Challenge Title"/>
                    </div>
                    <div className='col-auto ps-0'>
                      <button type="button" id="xlsxSubmit" className="btn btn-success uplaod-file-btn">Upload File</button>
                    </div>
                  </div>
                </div>
            </div>

            <div className="row">

                <div className="col-md-4 mb-4">
                    <label className="form-label sub-title">User Detail Columns <span className='text-danger'>*</span></label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="User Detail Columns"/>
                </div>

                <div className="col-md-4 mb-4">
                    <div className="row">
                    <div className="col-md-12">
                        <label className="form-label sub-title">Auto Paginate Users <span className='text-danger'>*</span></label>
                    </div>
                    <div className="col-md-12">
                        <div className="row align-items-center">
                                <div className="col-md-auto">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="templateRadioPaginate" id="templateRadioPaginate1" type="radio" value="None" defaultChecked/>
                                        <label htmlFor='templateRadioPaginate1'  className="form-check-label pt-1">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="templateRadioPaginate" id="templateRadioPaginate2" type="radio" value="Text"/>
                                            <label htmlFor='templateRadioPaginate2'  className="form-check-label pt-1">No</label>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                <input type="number" className="form-control input-field-ui" id="" placeholder="000"/>
                                </div>
                                <div className="col-md-auto ps-0">
                                <label className="form-label mb-0">In Seconds</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-4">
                            <div className="mb-2">
                            <label className="form-check-label sub-title">Group View <span className='text-danger'>*</span></label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" name="templateRadioGroup" id="templateRadioGroup1" type="radio" value="None" defaultChecked/>
                                <label htmlFor='templateRadioGroup1'  className="form-check-label pt-1">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" name="templateRadioGroup" id="templateRadioGroup2" type="radio" value="Text"/>
                                    <label htmlFor='templateRadio2'  className="form-check-label pt-1">No</label>
                            </div>
                </div>

                <div className="col-md-4 mb-4">
                    <label className="form-label sub-title">Top Group Header <span className='text-danger'>*</span></label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Top Group Header"/>
                </div>

                <div className="col-md-4 mb-4">
                    <div className="row">
                    <div className="col-md-12">
                        <label className="form-label sub-title">Auto Paginate Groups <span className='text-danger'>*</span></label>
                    </div>
                    <div className="col-md-12">
                        <div className="row align-items-center">
                                <div className="col-md-auto">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="templateRadioPaginateGroups" id="templateRadioPaginateGroups1" type="radio" value="None" defaultChecked/>
                                        <label htmlFor='templateRadioPaginateGroups1'  className="form-check-label pt-1">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="templateRadioPaginateGroups" id="templateRadioPaginateGroups2" type="radio" value="Text"/>
                                            <label htmlFor='templateRadioPaginateGroups2'  className="form-check-label pt-1">No</label>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                <input type="number" className="form-control input-field-ui" id="" placeholder="000"/>
                                </div>
                                <div className="col-md-auto ps-0">
                                <label className="form-label mb-0">In Seconds</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-4">
                    <label className="form-label sub-title">Group Detail Columns <span className='text-danger'>*</span></label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Group Detail Columns"/>
                </div>

            </div>

            <div className="row">
                <div className="col-md-7">
                <button type="button" className="btn program-save-btn me-2">Submit</button> <button type="button" className="btn clear-btn">Cancel</button>
                </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default CreateLeaderboard;
