import  common_axios  from "./commonAxios";

let baseURL =   common_axios.defaults.baseURL;

export const createChallenge = (formData) => {
    // var dataPass = common_axios.post( baseURL + 'challenge/create_challenge',{
    //     "uidStore" : ''
    // });
    var dataPass = common_axios.post(baseURL + 'challenge/create_challenge', formData);
    return dataPass;
};

export const getTypes = (uidStore) => {
    var dataPass = common_axios.get( baseURL + 'challenge/fetch_activity_types',{
        "uidStore" : uidStore
    });
    return dataPass;
};

export const getAllChallenges = (formData) => {
    var dataPass = common_axios.post( baseURL + 'challenge/fetch_all_challenges', formData);
    return dataPass;
};

export const getChallenge = (formData) => {
    var dataPass = common_axios.post(baseURL + 'challenge/fetch_challenge', formData)
    return dataPass;
};

export const updateChallenge = (formData) => {
    // var dataPass = common_axios.post( baseURL + 'challenge/create_challenge',{
    //     "uidStore" : ''
    // });
    var dataPass = common_axios.post(baseURL + 'challenge/update_challenge', formData);
    return dataPass;
};

export const deleteChallenge = (formData) => {
    var dataPass = common_axios.post(baseURL + 'challenge/remove_challenge', formData)
    return dataPass;
};

export const getAllCorporates = (limit) => {
    var dataPass = common_axios.get( baseURL + 'challenge/fetch_all_corporates?limit='+limit);
    return dataPass;
};

export const getAllTaskLists = () => {
    var dataPass = common_axios.get( baseURL + 'challenge/fetch_task_lists');
    return dataPass;
};

export const getChallengeLeaderboard = (formData) => {
    if (!formData.downloadCsv) {
        var dataPass = common_axios.post( baseURL + 'challenge/fetch_leaderboard', formData);
    } else {
        var dataPass = common_axios.post( baseURL + 'challenge/fetch_leaderboard', formData, { responseType: 'blob', headers: {
            'Content-Type': 'application/json',
            'Accept': 'text/csv'
        } });
    }
    return dataPass;
};

export const generateChallengeReport = (formData) => {
    var dataPass = common_axios.post(baseURL + 'challenge/generate_report', formData)
    return dataPass;
}

export const getAllReports = () => {
    var dataPass = common_axios.get( baseURL + 'challenge/fetch_corporate_reports');
    return dataPass;
};

export const createCorporate = (formData) => {
    var dataPass = common_axios.post(baseURL + 'corporate/create_corporate', formData);
    return dataPass;
};

export const fetchCorporate = (formData) => {
    var dataPass = common_axios.post(baseURL + 'corporate/fetch_corporate', formData);
    return dataPass;
};

export const updateCorporate = (formData) => {
    var dataPass = common_axios.post(baseURL + 'corporate/update_corporate', formData);
    return dataPass;
};

export const getCorporateDashboardMetrics = (formData) => {
    var dataPass = common_axios.post(baseURL + 'corporate/corporate_dashboard_metrics', formData)
    return dataPass;
}

export const createCorporateGroups = (formData) => {
    var dataPass = common_axios.post(baseURL + 'corporate/create_corporate_groups', formData)
    return dataPass;
}