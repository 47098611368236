import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import './UploadMemberFile.css';
import { addHealthLockerFile, fetchReportTypes } from "../../services/rpa/Service";
import { Link } from 'react-router-dom';
import UploadMemberFileTable from './UploadMemberFileTable';

function UploadMemberFile() {

  const [reportTypes, setReportTypes] = useState([]);
  const [refreshVar, setRefreshVar] = useState(new Date().getTime());

  useEffect(() => {
    getReportTypes();
  }, [])

  const getReportTypes = async () => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchReportTypes(ct)
      .then((response) => {
        if (response.data.code === 200) {
          setReportTypes(response.data.data.reportTypes);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const addFileObject = {
    membershipNumber: "",
    reportTypeId: "",
    title: "",
    file: null,
    originalName: ""
  }
  const [addFileFormData, setAddFileFormData] = useState(addFileObject);
  const [addError, setAddError] = useState("");
  const [addMessage, setAddMessage] = useState("");

  const handleAddFileFormChange = (e) => {
    setAddError("");
    setAddMessage("");
    const { name, value } = e.target;
    setAddFileFormData({
      ...addFileFormData,
      [name]: value
    });
  }

  const handleAddFileChange = (event) => {
    setAddError("");
    const file = event.target.files[0];
    const originalName = file.name;
    const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png', 'tiff', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'dcm'];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (file && allowedExtensions.includes(fileExtension)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        setAddFileFormData({
          ...addFileFormData,
          file: fileData,
          originalName: originalName
        });
      }
      reader.readAsDataURL(file);
    } else {
      setAddFileFormData({
        ...addFileFormData,
        file: null,
        originalName: ''
      });
      event.target.value = ''
      setAddError("Invalid file type. Please select a valid file.");
    }
  };

  const handleAddFileSubmit = async () => {
    setAddError("");
    let error = "";
    if (addFileFormData.membershipNumber === "") {
      error = "Please enter the membership number";
    } else if (addFileFormData.reportTypeId === "") {
      error = "Please select the report type";
    } else if (addFileFormData.file === null) {
      error = "Please select the file";
    }

    if (error === "") {
      const request = axios.CancelToken.source();
      let ct = {
        cancelToken: request.token
      }

      await addHealthLockerFile(addFileFormData.membershipNumber, addFileFormData.reportTypeId, addFileFormData.title, addFileFormData.originalName, addFileFormData.file, ct)
        .then((response) => {
          if (response.data.code === 200) {
            setAddFileFormData(addFileObject);
            document.getElementById('file').value = '';

            setAddMessage(response.data.data.message);
            setRefreshVar(new Date().getTime());
          } else {
            setAddError(response.data.data.message)
          }
        }).catch(function (error) {
          console.log(error);
        });
      return () => request.cancel();
    } else {
      setAddError(error);
    }
  }

  return (
    <>
      <section className='UploadMemberFile'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Upload Member File</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='upload-member-file mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/42339_1725518509.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Upload the member file by selecting the appropriate document and entering the member ID. Choose the file type and specify the date of the investigations. If no file is chosen, the upload cannot proceed.</p>
              </div>
            </div>
          </div>

          <div className='upload-member-file p-3 mb-3'>
            <div className="row">
              <div className="col-md-4 mb-3">
                <label className="form-label sub-title">Membership Number</label>
                <input type="text" name="membershipNumber" className="form-control input-field-ui" id="" placeholder="Enter Membership Number" onChange={handleAddFileFormChange} value={addFileFormData.membershipNumber} />
              </div>
              <div className="col-md-4 mb-3">
                <label className="form-label sub-title">File Type</label>
                <select name="reportTypeId" className="form-control input-field-ui" value={addFileFormData.reportTypeId} onChange={handleAddFileFormChange}>
                  <option value="">Select</option>
                  {
                    reportTypes.map((item, index) => {
                      return <option value={item.reportTypeId}>{item.reportType}</option>
                    })
                  }
                </select>
              </div>
              <div className="col-md-4 mb-3">
                <label className="form-label sub-title">Upload File</label>
                <input type="file" id="file" accept=".pdf,.jpeg,.jpg,.png,.tiff,.doc,.docx,.xls,.xlsx,.csv,.txt,.dcm" className='form-control input-field-ui' onChange={handleAddFileChange} />
              </div>
              <div className="col-md-4 mb-3">
                <label className="form-label sub-title">Description</label>
                <textarea type="text" name="title" placeholder='Description' rows="3" className='form-control input-field-ui' value={addFileFormData.title} onChange={handleAddFileFormChange}></textarea>
              </div>

              {/*<div className="col-md-2 mb-3">
                <label className="form-label sub-title">Date of Investigations</label>
                <input type="date" className="form-control input-field-ui" id="" placeholder="Enter Date of Investigations" />
              </div>
              <div className="col-md-2 mb-3">
                <label className="form-label sub-title">Report Date</label>
                <input type="date" className="form-control input-field-ui" id="" placeholder="Enter Report Date" />
              </div>*/}
            </div>

            <div className="row">
              <div className="col-md-6">
                <button type="button" id="startProcess" className="btn btn-success uplaod-file-btn" onClick={() => handleAddFileSubmit()}>Submit</button>
              </div>
            </div>

            <div className={`${addError != "" ? "errorDiv" : "hide"}`}>{addError}</div>
            <div className={`${addMessage != "" ? "messageDiv" : "hide"}`}>{addMessage}</div>
          </div>

          <div className='upload-member-file p-3'>

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive mb-0">
                  <UploadMemberFileTable refreshVar={refreshVar} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </section >
    </>
  );
}

export default UploadMemberFile;
