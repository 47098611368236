import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

function ClaimHistorTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedLifestyle, setSelectedLifestyle] = useState('All');
    const [selectedType, setSelectedType] = useState('All');

    const columns = [
        {
            name: 'Employee Name',
            selector: row => row.insurePlusEmployee,
            sortable: true,
            cell: row => (
                <div >
                    <Link to="#" className='d-flex align-items-center' ><img src={row.imageEmployee} alt={row.insurePlusEmployee} className="leaderboardEmployee-img mb-0 me-2" /> {row.insurePlusEmployee}</Link>
                </div>
            ),
        },
        {
            name: 'Insurance ID',
            selector: row => row.insurePlusEmployeeId,
            sortable: true,
        },
        {
            name: 'Current Lifestyle',
            selector: row => row.currentLifestyle,
            sortable: true,
            cell: row => (
                <span style={{
                    color: row.currentLifestyle === 'Sedentary' ? '#f76e66' :
                           row.currentLifestyle === 'Fit' ? '#58c063' :
                           row.currentLifestyle === 'Cashless' ? '#f8c01b' :
                           '#4b78c1' 
                }}>
                    {row.currentLifestyle}
                </span>
            ),
        },
        {
            name: 'Amount Claimed',
            selector: row => row.amountClaimed,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.dateOfInsurance,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Medical Card Download',
            cell: row => (
                <button className="btn btn-Edit mx-2">
                    <i className="bi bi-download"></i>
                </button>
            ),
        },
    ];

    const data = [
        {
            id: 1,
            imageEmployee: 'https://goqii-app.s3.amazonaws.com/user/user/l_1437644704.png',
            insurePlusEmployee: 'Ashok Patil',
            insurePlusEmployeeId: '1234567890',
            currentLifestyle: 'Elite',
            amountClaimed: '₹ 5,00,000',
            dateOfInsurance: 'Jan 31, 2024',
            type: 'Cashless',
        },
        {
            id: 2,
            imageEmployee: 'https://goqii-app.s3.amazonaws.com/user/user/l_1437644704.png',
            insurePlusEmployee: 'Vinod Kumar',
            insurePlusEmployeeId: '1234567890',
            currentLifestyle: 'Cashless',
            amountClaimed: '₹ 5,00,000',
            dateOfInsurance: 'Jan 31, 2024',
            type: 'Cashless',
        },
        {
            id: 3,
            imageEmployee: 'https://goqii-app.s3.amazonaws.com/user/user/l_1437644704.png',
            insurePlusEmployee: 'Rahul Sharma',
            insurePlusEmployeeId: '1234567890',
            currentLifestyle: 'Fit',
            amountClaimed: '₹ 5,00,000',
            dateOfInsurance: 'Jan 31, 2024',
            type: 'Cashless',
        },
        {
            id: 4,
            imageEmployee: 'https://goqii-app.s3.amazonaws.com/user/user/l_1437644704.png',
            insurePlusEmployee: 'Ankit Gupta',
            insurePlusEmployeeId: '1234567890',
            currentLifestyle: 'Sedentary',
            amountClaimed: '₹ 5,00,000',
            dateOfInsurance: 'Jan 31, 2024',
            type: 'Reimbursement',
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedLifestyle('All');
        setSelectedType('All');
    };

    const filteredData = data.filter(item => {
        const matchesSearchQuery =
            item.insurePlusEmployee.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.amountClaimed.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesLifestyle = selectedLifestyle === 'All' || item.currentLifestyle === selectedLifestyle;
        const matchesStatus = selectedType === 'All' || item.type === selectedType;

        return matchesSearchQuery && matchesLifestyle && matchesStatus;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Employee Name or Employee Id"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                           <img src={require("../../../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedLifestyle}
                            onChange={e => setSelectedLifestyle(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Current Lifestyle--</option>
                            <option value="Sedentary">Sedentary</option>
                            <option value="Active">Active</option>
                            <option value="Fit">Fit</option>
                            <option value="Elite">Elite</option>
                        </select>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedType}
                            onChange={e => setSelectedType(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="Cashless">Cashless</option>
                            <option value="Reimbursement">Reimbursement</option>
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    {/* Uncomment to add a link to create a new policy */}
                    {/* <div className="col-md-auto text-end pe-0">
                        <Link className="btn btn-primary add-new-program-btn" to="/create-leaderboard">Add Policy</Link>
                    </div> */}
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default ClaimHistorTable;
