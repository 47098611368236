import React, { useState, useEffect } from 'react';
import { FaMobile, FaTabletAlt, FaDesktop, FaArrowLeft, FaCheck, FaTimes } from 'react-icons/fa';
import Modal from './Modal';
import { fetch_single_article, sendApproval, generateviewurl } from "../../../services/cms/Service";
import { useParams, useNavigate } from 'react-router-dom';


const BlogPreview = ({ onCancel }) => {
  const [viewportSize, setViewportSize] = useState('desktop');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blog, setBlog] = useState({});
  const [globalError, setGlobalError] = useState({
    status: false,
    msg: ""
  });

  console.log(blog, "blog")
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  const { pid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getSinglePost();
  }, []);

  const getViewportClass = () => {
    switch (viewportSize) {
      case 'mobile':
        return 'tw-w-full sm:tw-w-1/2 md:tw-w-1/3 lg:tw-w-1/4 xl:tw-w-1/5';
      case 'tablet':
        return 'tw-w-full sm:tw-w-2/3 md:tw-w-1/2 lg:tw-w-2/5 xl:tw-w-1/3';
      default:
        return 'tw-w-full';
    }
  };

  const handleSendForApproval = (id) => {
    console.log(id, "id")
    send_Approval(id)


  };


  async function send_Approval(id) {
    try {
      let resp = await sendApproval({ pid: id });
      console.log(resp.data.data.message, "response")
      if (resp.data.data.message == "Article Pending Approval") {
        setIsModalOpen(true);
        navigate('/content-management/content-status')
      }


    } catch (e) {

    }

  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onCancel(); // This will take us back to the content creation page
  };

  // get single post 
  async function getSinglePost() {
    try {
      let response = await fetch_single_article({ pId: pid });


      if (response?.data?.code == 200) {
        if (response.data.data.article.post_type == "library_video") {
          const res = await generateviewurl({ presignedUrl: response.data.data.article.post_content });
          const videoUrl = res.data.data
          const { article } = response?.data?.data;

          setBlog({
            title: article.post_title,
            description: article.post_excerpt,
            content: videoUrl,
            headerImage: "",
            contentType: article.post_type,
            program: article.programId,
            condition: article.conditionId,
            pathway: article.pathwayId,
            ageGroups: [],
            gender: "",
            location: "",
            language: "English",
            assignedMembers: [],
            author: "",
            categories: [],
            tags: [],
            publishedDate: new Date().toISOString().split("T")[0],
          });
        } else {


          const { article } = response?.data?.data;

          setBlog({
            title: article.post_title,
            description: article.post_excerpt,
            content: article.post_content,
            headerImage: "",
            contentType: article.post_type,
            program: article.programId,
            condition: article.conditionId,
            pathway: article.pathwayId,
            ageGroups: [],
            gender: "",
            location: "",
            language: "English",
            assignedMembers: [],
            author: "",
            categories: [],
            tags: [],
            publishedDate: new Date().toISOString().split("T")[0],
          });
        }
      }
      console.log(response);
    } catch (e) {
      if (e.response === 422) {
        setGlobalError({
          status: true,
          msg: e.response?.data?.data?.msg || "Resource Not Found"
        })
      }
    }
  }

  if (!blog) {
    return <div>No blog data available</div>;
  }

  return (
    <div className="tw-p-6 tw-mt-8 tw-bg-white tw-rounded-lg tw-shadow-lg">
      <h2 className="tw-text-2xl tw-font-bold tw-mb-4">Blog Preview</h2>

      <div className="tw-mb-4 tw-flex tw-justify-center tw-space-x-4">
        <button
          className={`tw-px-4 tw-py-2 tw-rounded tw-text-sm tw-font-medium tw-transition-colors tw-duration-150 tw-ease-in-out ${viewportSize === 'mobile'
            ? 'tw-bg-blue-500 tw-text-white'
            : 'tw-bg-white tw-text-blue-500 tw-border tw-border-blue-500 hover:tw-bg-blue-50'
            }`}
          onClick={() => setViewportSize('mobile')}
        >
          <FaMobile className="tw-inline tw-mr-2" /> Mobile
        </button>
        <button
          className={`tw-px-4 tw-py-2 tw-rounded tw-text-sm tw-font-medium tw-transition-colors tw-duration-150 tw-ease-in-out ${viewportSize === 'tablet'
            ? 'tw-bg-blue-500 tw-text-white'
            : 'tw-bg-white tw-text-blue-500 tw-border tw-border-blue-500 hover:tw-bg-blue-50'
            }`}
          onClick={() => setViewportSize('tablet')}
        >
          <FaTabletAlt className="tw-inline tw-mr-2" /> Tablet
        </button>
        <button
          className={`tw-px-4 tw-py-2 tw-rounded tw-text-sm tw-font-medium tw-transition-colors tw-duration-150 tw-ease-in-out ${viewportSize === 'desktop'
            ? 'tw-bg-blue-500 tw-text-white'
            : 'tw-bg-white tw-text-blue-500 tw-border tw-border-blue-500 hover:tw-bg-blue-50'
            }`}
          onClick={() => setViewportSize('desktop')}
        >
          <FaDesktop className="tw-inline tw-mr-2" /> Desktop
        </button>
      </div>

      <div className={`tw-mx-auto ${getViewportClass()} tw-border tw-p-4 tw-overflow-auto`} style={{ maxHeight: '600px' }}>
        <h1 className="tw-text-2xl tw-font-bold tw-mb-2">{blog.title || 'Untitled Blog'}</h1>
        <p className="tw-text-gray-600 tw-mb-4">
          {blog.author ? `By ${blog.author}` : ''}
          {blog.lastUpdated ? ` | ${blog.lastUpdated}` : ''}
        </p>
        {blog.headerImage && (
          <img src={blog.headerImage} alt={blog.title} className="tw-w-full tw-h-48 tw-object-cover tw-mb-4 tw-rounded" />
        )}
        {blog.contentType == "library_video" ?
          <iframe
            width="100%"
            height="100%"
            src={blog.content}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="tw-w-full tw-aspect-video"
          ></iframe> :
          <div className="tw-prose tw-max-w-none" dangerouslySetInnerHTML={{ __html: blog.content || '' }} />}

        {blog.categories && blog.categories.length > 0 && (
          <div className="tw-mt-4">
            <h3 className="tw-text-lg tw-font-semibold">Categories:</h3>
            <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mt-2">
              {blog.categories.map((category, index) => (
                <span key={index} className="tw-inline-block tw-px-2 tw-py-1 tw-text-xs tw-font-semibold tw-rounded-full tw-bg-blue-100 tw-text-blue-800">
                  {category}
                </span>
              ))}
            </div>
          </div>
        )}

        {blog.tags && blog.tags.length > 0 && (
          <div className="tw-mt-4">
            <h3 className="tw-text-lg tw-font-semibold">Tags:</h3>
            <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mt-2">
              {blog.tags.map((tag, index) => (
                <span key={index} className="tw-inline-block tw-px-2 tw-py-1 tw-text-xs tw-font-semibold tw-rounded-full tw-bg-purple-100 tw-text-purple-800">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>

      <Modal
        title="Success"
        content="Blog submitted for approval."
        action="success"
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />

      <div className="tw-mt-4 tw-flex tw-justify-center tw-space-x-4">
        {!type && (
          blog.contentType === "library_video" ? (
            <button
              className="tw-border tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300"
              onClick={() => navigate(`/content-management/add-video-library?id=${pid}`)}
            >
              <FaArrowLeft className="tw-inline tw-mr-2" /> Back
            </button>
          ) : blog.contentType === "yt_video" ? (
            <button
              className="tw-border tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300"
              onClick={() => navigate(`/content-management/edit-yt-video/${pid}`)}
            >
              <FaArrowLeft className="tw-inline tw-mr-2" /> Back
            </button>
          ) : blog.contentType === "Blog" ? (
            <button
              className="tw-border tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300"
              onClick={() => navigate(`/content-management/edit-post/${pid}`)}
            >
              <FaArrowLeft className="tw-inline tw-mr-2" /> Back
            </button>
          ) : null
        )}
        {/* <button
          className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded tw-font-medium hover:tw-bg-blue-600 tw-transition-colors tw-duration-150 tw-ease-in-out"
          onClick={e => navigate(`/content-management/edit-post/${pid}`)}
        >
          <FaArrowLeft className="tw-inline tw-mr-2" /> Back
        </button> */}
        {!type ?
          <>
            <button
              className="tw-px-4 tw-py-2 tw-bg-green-500 tw-text-white tw-rounded tw-font-medium hover:tw-bg-green-600 tw-transition-colors tw-duration-150 tw-ease-in-out tw-flex tw-items-center"
              onClick={() => handleSendForApproval(pid)}
            >
              <FaCheck className="tw-mr-2" /> Send for Approval
            </button>
            <button
              className="tw-px-4 tw-py-2 tw-bg-red-500 tw-text-white tw-rounded tw-font-medium hover:tw-bg-red-600 tw-transition-colors tw-duration-150 tw-ease-in-out tw-flex tw-items-center"
              onClick={onCancel}
            >
              <FaTimes className="tw-mr-2" /> Cancel
            </button>
          </> : ""}
      </div>
    </div>


  );
};

export default BlogPreview;