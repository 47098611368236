import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// Load the 3D module
require('highcharts/highcharts-3d')(Highcharts);

const DivisionWiseCategorisation = () => {
    const columnGraphHeight = 340; // Set desired height similar to the image
    const colCategories = ['Modality AWC', 'Modality Wokingham', 'Modality Mid Sussex', 'GOQii Division']; // Categories from the image
    const yAxisJson = {
        title: {
            text: null // Remove Y-axis title as in the design
        },
        max: 600, // Set max value based on the chart
        gridLineWidth: 1,
        lineWidth: 1,
    };
    const tooltipJson = {
        shared: true,
        useHTML: true
    };

    // Data for the chart (2 series for accounts created and active patients)
    const columnData = [
        {
            name: 'Accounts Created',
            data: [200, 300, 500, 150], // Example data for accounts created
            color: '#2442c2', // Light blue color
            borderColor: '#2442c2',
            borderWidth: 1,
        },
        {
            name: 'Active Patients',
            data: [120, 180, 400, 100], // Example data for active patients
            color: '#03335b', // Light green color
            borderColor: '#03335b',
            borderWidth: 1,
        }
    ];

    const options = {
        chart: {
            type: "column",
            height: columnGraphHeight,
            marginTop: 40,
            options3d: {
                enabled: true,
                alpha: 0,
                beta: 15,
                depth: 50,
                viewDistance: 0
            },
        },
        title: null,
        subtitle: null,
        xAxis: {
            categories: colCategories,
            gridLineWidth: 0,
            lineWidth: 1,
            labels: {
                style: {
                    fontSize: '12px' // Match font size
                }
            }
        },
        yAxis: yAxisJson,
        tooltip: tooltipJson,
        plotOptions: {
            column: {
                depth: 50,
                stacking: null, // Not stacked in the image
                dataLabels: {
                    enabled: false, // No data labels in the image
                },
            },
            series: {
                animation: true,
                borderColor: '#000000', // Add a border to the columns
                borderWidth: 1,
            }
        },
        legend: {
            enabled: true,
            align: 'center',
            verticalAlign: 'top',
            floating: true,
            borderWidth: 0,
            itemStyle: {
                fontSize: '12px',
            },
        },
        series: columnData,
        credits: {
            enabled: false
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen', 
                        'printChart', 
                        'separator', 
                        'downloadPNG', 
                        'downloadJPEG', 
                        'downloadPDF', 
                        'downloadSVG'
                    ],
                },
            },
            menuItemDefinitions: {
                viewFullscreen: {
                    onclick: function () {
                        this.fullscreen.toggle();
                    },
                },
                printChart: {
                    onclick: function () {
                        this.print();
                    },
                },
                downloadPNG: {
                    onclick: function () {
                        this.exportChart({
                            type: 'image/png',
                            filename: 'division-wise-categorisation-chart',
                        });
                    },
                },
                downloadJPEG: {
                    onclick: function () {
                        this.exportChart({
                            type: 'image/jpeg',
                            filename: 'division-wise-categorisation-chart',
                        });
                    },
                },
                downloadPDF: {
                    onclick: function () {
                        this.exportChart({
                            type: 'application/pdf',
                            filename: 'division-wise-categorisation-chart',
                        });
                    },
                },
                downloadSVG: {
                    onclick: function () {
                        this.exportChart({
                            type: 'image/svg+xml',
                            filename: 'division-wise-categorisation-chart',
                        });
                    },
                },
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default DivisionWiseCategorisation;
