import React, { useState } from "react";
import styles from "./BloodReportPreview2.module.css";

const SmartHealthReport =  ({ reportData }) => {
  return (
    <>
      <body>
        <div className={styles.header}>
          <img
            src="https://appcdn.goqii.com/storeimg/46559_1726644273.png"
            alt="GOQii Logo"
            className={styles.logo}
          ></img>
          <span className={styles.header_text}>Smart Report</span>
        </div>
        <div className={styles.container}>
          <h1>Smart Health Report</h1>
          <h2>A Self explanatory Health Diagnostics Report</h2>
          <p>
            Below are the sections which depict what you can expect from this
            report, how you can read this report and use it for your well-being.
          </p>

          <div className={styles.section}>
            <div className={styles.section_title}>01. Health Analysis</div>
            <div className={styles.section_content}>
              This section summarizes your test results, your critical health
              parameters and on basis of them where you should draw your
              attention to. This has been determined by lab results & health
              karma questions which you answered regarding your lifestyle.
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.section_title}>02. Historical Charts</div>
            <div className={styles.section_content}>
              These charts are a way to measure and keep a track of how your
              health has progressed over time. We depict important parameters
              here and depending on your test history, the charts describe rise
              and fall of your health metrics.
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.section_title}>03. Lab Test Results</div>
            <div className={styles.section_content}>
              Comprehensive test results generated through use of latest
              technology and quality checks by health experts. This section
              provides an exhaustive view of which tests you have taken, ideal
              result and your actual result with highlighted focus points.
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.section_title}>04. Health Advisory</div>
            <div className={styles.section_content}>
              An Advisory section suggesting what modifications to bring in your
              nutrition & lifestyle, recommendations on your BMI along with
              regular tests and further consultations to pursue for a healthier
              future.
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.section_title}>05. General Recommendations</div>
            <div className={styles.section_content}>
              Brief view of general preventive test recommendations categorized
              by age groups. Refer this section to know at what age, which tests
              are necessary and at what frequency they should be booked.
            </div>
          </div>

          <div>
            <strong className={styles.disclaimer_title}>Disclaimer</strong>
            <div className={styles.disclaimer}>
              <div>
                <ul>
                  <li>
                    This is an electronically generated report and is not a
                    substitute for medical advice.
                  </li>
                  <li>
                    While following the recommendations, please be careful of
                    any allergies or intolerances.
                  </li>
                  <li>
                    If you are pregnant or lactating, some of the
                    recommendations and analyzed information in the Smart Report
                    may not directly apply to you. Please consult a doctor
                    regarding your test results and recommendations.
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    Analysis uses the attached blood test report and Well Being
                    Index Questionnaire data, if present, and urine analysis
                    report, if present.
                  </li>
                  <li>
                    Tata 1mg is not liable for any direct, indirect, special,
                    consequential, or other damages.
                  </li>
                  <li>
                    This report cannot be used for any medico-legal purposes.
                    Partial reproduction of the test results is not permitted.
                    Also, TATA 1mg Labs is not responsible for any
                    misinterpretation or misuse of the information.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default SmartHealthReport;
