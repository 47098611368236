import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsTreemap from "highcharts/modules/treemap";
import exportingInit from "highcharts/modules/exporting";
import fullscreenInit from "highcharts/modules/full-screen";

// Initialize necessary modules
HighchartsTreemap(Highcharts);
exportingInit(Highcharts);
fullscreenInit(Highcharts);

const HealthGoalsChart = () => {
  const options = {
    chart: {
      type: 'treemap',
      height: '300px',
      marginTop: 0,
      marginBottom: 0,
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    series: [
      {
        type: "treemap",
        layoutAlgorithm: "squarified",
        data: [
          {
            name: "Eat Healthy",
            value: 180,
            color: "#4bc0c0"
          },
          {
            name: "Reduce Stress",
            value: 102,
            color: "#99ccff"
          },
          {
            name: "Fit & Active",
            value: 72,
            color: "#cc33ff"
          },
          {
            name: "Weight Loss",
            value: 60,
            color: "#ff9933"
          },
          {
            name: "Fat Loss",
            value: 46,
            color: "#9933ff"
          },
          {
            name: "Fitness Event Training",
            value: 24,
            color: "#ffcc33"
          },
          {
            name: "Manage Illness",
            value: 18,
            color: "#66cc99"
          },
          {
            name: "Improve Sleep",
            value: 9,
            color: "#3399ff"
          },
        ]
      }
    ],
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          inside: true,
          style: {
            textOutline: 'none',
            fontSize: '12px',
            fontWeight: '400',
          }
        }
      }
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
        }
      }
    }
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default HealthGoalsChart;
