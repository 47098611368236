import React, { useState } from "react";
import "./CreateGroups.css";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { createCorporateGroups } from "../../services/corporatewellness/Service";

function CreateGroups() {
  const { corporateId } = useParams();
  const [groupsData, setGroupsData] = useState({
    corporateId: corporateId,
    matchingCriteria: "",
    csvFile: "",
    reportingEmail: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const maxSize = 2 * 1024 * 1024; // 2MB max size
  // const [responseErrorData, setResponseErrorData] = useState([]);

  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(`/corporates-detail/${corporateId}`);
  };

  const handleInputChange = (e) => {
    const val = e.target.value;
    const name = e.target.name;

    setGroupsData((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const handleCsvFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.type !== "text/csv") {
        toast.error("Please upload a CSV file.");
        return;
      }

      if (selectedFile.size > maxSize) {
        toast.error("File is too large. Maximum size is 2MB.");
        return;
      }

      setGroupsData((prev) => ({
        ...prev,
        csvFile: selectedFile,
      }));
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (
        Object.values(groupsData).every((g) => g === "") ||
        Object.values(groupsData).some((g) => g === "")
      ) {
        toast.error("Please fill all the required data.");
        return;
      }

      let formData = new FormData();

      if (groupsData.csvFile !== "" && groupsData.csvFile instanceof File) {
        formData.append("csvFile", groupsData.csvFile);
      }

      delete groupsData.csvFile;
      formData.append("formData", JSON.stringify(groupsData));

      const response = await createCorporateGroups(formData);

      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("text/csv")) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        );
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", "Error_Report.csv");

        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);

        // setTimeout(() => {
        //   window.location.reload();
        // }, 1500);
      } else {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setGroupsData({
            corporateId: corporateId,
            matchingCriteria: "",
            csvFile: "",
          });
        } else if (response.data.status === 400) {
          // setResponseErrorData(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  console.log(groupsData);
  // console.log(responseErrorData);

  return (
    <>
      <ToastContainer />
      <section className="CreateGroups">
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div
              className="col-md"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-primary back-btn mb-0 me-3"
                onClick={handleBackButton}
              >
                <i className="bi bi-chevron-left me-1"></i> Back
              </button>
              <h1 className="main-title" style={{ margin: "0" }}>
                Create Groups
              </h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" data-bs-toggle="modal" data-bs-target="#testPushSmartcards">Test Smartcards</Link></div> */}
          </div>

          <div className="create-groups p-4">
            {/* <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Add Group Name <span className='text-danger'>*</span></label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Enter Group Name"/>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Add Group Description <span className='text-danger'>*</span> </label>
                    <textarea rows="4" type="text" className="form-control input-field-ui" id="" placeholder="Enter Group Description"></textarea>
                </div>
            </div> */}

            <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">
                  Matching Criteria <span className="text-danger">*</span>
                </label>

                <div className="form-check d-flex align-items-center mt-2">
                  <input
                    className="form-check-input mr-2"
                    type="radio"
                    name="matchingCriteria"
                    value="mobile"
                    checked={groupsData.matchingCriteria === "mobile"}
                    onChange={handleInputChange}
                    id="mobile"
                  />
                  <label className="form-check-label" htmlFor="mobile">
                    Mobile Number
                  </label>
                </div>

                <div className="form-check d-flex align-items-center mt-2">
                  <input
                    className="form-check-input mr-2"
                    type="radio"
                    name="matchingCriteria"
                    value="email"
                    checked={groupsData.matchingCriteria === "email"}
                    onChange={handleInputChange}
                    id="email"
                  />
                  <label className="form-check-label" htmlFor="email">
                    Email
                  </label>
                </div>

                <div className="form-check d-flex align-items-center mt-2">
                  <input
                    className="form-check-input mr-2"
                    type="radio"
                    name="matchingCriteria"
                    value="empCode"
                    checked={groupsData.matchingCriteria === "empCode"}
                    onChange={handleInputChange}
                    id="empCode"
                  />
                  <label className="form-check-label" htmlFor="empCode">
                    Employee Code
                  </label>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <div className="row">
                  <label className="form-label sub-title">
                    Add Users <span className="text-danger">*</span>{" "}
                  </label>
                  <div className="col pe-1">
                    <input
                      type="file"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="Enter Image Url"
                      onChange={handleCsvFileChange}
                    />
                  </div>
                  {/* <div className="col-auto ps-0">
                    <button
                      type="button"
                      id="xlsxSubmit"
                      className="btn btn-success uplaod-file-btn"
                    >
                      Upload (csv file)
                    </button>
                  </div> */}
                  <label className="form-label mb-0 mt-2 sub-title-1">
                    Upload (csv file)
                  </label>
                </div>
                <Link to="https://storage.googleapis.com/ksabupatest/2024/10/23/yh608/chmjt4v4w8.csv">
                  Download Sample CSV
                </Link>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">
                  Reporting Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="Enter your Email Id"
                  name="reportingEmail"
                  value={groupsData.reportingEmail}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-7">
                <button
                  type="button"
                  className="btn program-save-btn me-2"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>{" "}
                <button type="button" className="btn clear-btn">
                  Cancel
                </button>
              </div>
            </div>

            {/* {responseErrorData && responseErrorData.length > 0 && (
              <div className="row">
                <div className="col-md-7 d-flex flex-column">
                  {responseErrorData.map((error, i) => (
                    <span key={i}>{error}</span>
                  ))}
                </div>
              </div>
            )} */}
          </div>
        </div>
      </section>
    </>
  );
}

export default CreateGroups;
