import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash, FaPlus, FaArrowLeft } from 'react-icons/fa';
import Modal from './Modal';
import { fetchAuthors, add_creator } from "../../../services/cms/Service";

const CreatorManagement = ({ onAddCreator, onEditCreator, onDeleteCreator, onBack, roleId }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newCreator, setNewCreator] = useState({ firstName: '', lastName: '', email: '', image: null, description: '' });
  const [editingCreator, setEditingCreator] = useState(null);
  const [deletingCreator, setDeletingCreator] = useState(null);
  const [creators, setCreators] = useState([]);

  console.log(newCreator, "newCreator")

  const handleCreateCreator = () => {
    if (newCreator.firstName && newCreator.lastName && newCreator.email) {
      addCreator(newCreator)
      // setNewCreator({ firstName: '', lastName: '', email: '', image: null, description: '' });
      setShowModal(false);
    }
  };

  // const creators = [
  //   {
  //     id: 1,
  //     firstName: 'John',
  //     lastName: 'Doe',
  //     email: 'john.doe@example.com',
  //     image: 'https://via.placeholder.com/150', // Placeholder image URL
  //     description: 'John is a professional content creator who specializes in tech tutorials.',
  //   },
  //   {
  //     id: 2,
  //     firstName: 'Jane',
  //     lastName: 'Smith',
  //     email: 'jane.smith@example.com',
  //     image: 'https://via.placeholder.com/150', // Placeholder image URL
  //     description: 'Jane creates engaging videos about travel and lifestyle.',
  //   },
  //   {
  //     id: 3,
  //     firstName: 'Mike',
  //     lastName: 'Johnson',
  //     email: 'mike.johnson@example.com',
  //     image: 'https://via.placeholder.com/150', // Placeholder image URL
  //     description: 'Mike is known for his photography tutorials and reviews of camera gear.',
  //   },
  // ];

  async function fetch_Authors() {
    try {

      let response = await fetchAuthors();
      if (response) {
        console.log(response.data.data.details, "response")
        const updatedCreator = response.data.data.details.map((category, index) => ({
          id: index + 1,  // You can adjust this as needed
          firstName: category.user_nicename,
          email: category.user_email
        }));
        setCreators(updatedCreator)

      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function addCreator(newCreator) {
    try {
      let fullName = `${newCreator.firstName} ${newCreator.lastName}`;
      let response = await add_creator({ name: fullName, email: newCreator.email, image: newCreator.image, description: newCreator.description });
      if (response) {
        console.log(response.data.message, "response")

      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetch_Authors()
  }, []);

  const handleEditCreator = () => {
    if (editingCreator) {
      onEditCreator(editingCreator);
      setEditingCreator(null);
      setShowModal(false);
    }
  };

  const openEditModal = (creator) => {
    setEditingCreator(creator);
    setShowModal(true);
  };

  const openDeleteModal = (creator) => {
    setDeletingCreator(creator);
    setShowDeleteModal(true);
  };

  const handleDeleteCreator = () => {
    if (deletingCreator) {
      onDeleteCreator(deletingCreator.id);
      setDeletingCreator(null);
      setShowDeleteModal(false);
    }
  };

  const handleImageChange = (e, isEditing) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (isEditing) {
          setEditingCreator({ ...editingCreator, image: reader.result });
        } else {
          setNewCreator({ ...newCreator, image: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-xl">
      <div className="tw-relative tw-mb-6">
        <h2 className="tw-text-3xl tw-font-bold tw-text-gray-800 tw-text-center">Creator Management</h2>
        <button
          className="tw-absolute tw-left-0 tw-top-0 tw-bottom-0 tw-flex tw-items-center tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
          onClick={onBack}
        >
          <FaArrowLeft className="tw-mr-2" /> Back
        </button>
        {roleId != 25 ?
          <button
            className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300 tw-absolute tw-right-0 tw-top-0 tw-bottom-0"
            onClick={() => setShowModal(true)}
          >
            <FaPlus className="tw-inline tw-mr-2" /> Add New Creator
          </button> : ""}
      </div>
      <table className="tw-w-full tw-mt-4">
        <thead>
          <tr className="tw-bg-gray-100">
            {/* <th className="tw-p-2">Image</th> */}
            <th className="tw-p-2">Name</th>
            <th className="tw-p-2">Email</th>
            <th className="tw-p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {creators.map(creator => (
            <tr key={creator.id} className="tw-border-b">
              {/* <td className="tw-p-2">
                <img src={creator.image} alt={`${creator.firstName} ${creator.lastName}`} className="tw-h-10 tw-w-10 tw-rounded-full" />
              </td> */}
              <td className="tw-p-2">{`${creator.firstName}`}</td>
              <td className="tw-p-2">{creator.email}</td>
              <td className="tw-p-2">
                <button className="tw-text-blue-500 hover:tw-text-blue-700 tw-mr-2" onClick={() => openEditModal(creator)}><FaEdit /></button>
                <button className="tw-text-red-500 hover:tw-text-red-700" onClick={() => openDeleteModal(creator)}><FaTrash /></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setEditingCreator(null);
        }}
        title={editingCreator ? "Edit Creator" : "Add New Creator"}
      >
        <input
          type="text"
          placeholder="First Name"
          value={editingCreator ? editingCreator.firstName : newCreator.firstName}
          onChange={(e) => editingCreator
            ? setEditingCreator({ ...editingCreator, firstName: e.target.value })
            : setNewCreator({ ...newCreator, firstName: e.target.value })}
          className="tw-w-full tw-mb-4 tw-p-2 tw-border tw-rounded"
        />
        <input
          type="text"
          placeholder="Last Name"
          value={editingCreator ? editingCreator.lastName : newCreator.lastName}
          onChange={(e) => editingCreator
            ? setEditingCreator({ ...editingCreator, lastName: e.target.value })
            : setNewCreator({ ...newCreator, lastName: e.target.value })}
          className="tw-w-full tw-mb-4 tw-p-2 tw-border tw-rounded"
        />
        <input
          type="email"
          placeholder="Email"
          value={editingCreator ? editingCreator.email : newCreator.email}
          onChange={(e) => editingCreator
            ? setEditingCreator({ ...editingCreator, email: e.target.value })
            : setNewCreator({ ...newCreator, email: e.target.value })}
          className="tw-w-full tw-mb-4 tw-p-2 tw-border tw-rounded"
        />
        <div className="tw-mb-4">
          <label className="tw-block tw-mb-2">Upload Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, !!editingCreator)}
            className="tw-w-full"
          />
          {(editingCreator?.image || newCreator.image) && (
            <img src={editingCreator?.image || newCreator.image} alt="Preview" className="tw-mt-2 tw-h-20 tw-w-20 tw-object-cover tw-rounded" />
          )}
        </div>
        <textarea
          placeholder="Description (max 500 characters)"
          maxLength={500}
          value={editingCreator ? editingCreator.description : newCreator.description}
          onChange={(e) => editingCreator
            ? setEditingCreator({ ...editingCreator, description: e.target.value })
            : setNewCreator({ ...newCreator, description: e.target.value })}
          className="tw-w-full tw-mb-4 tw-p-2 tw-border tw-rounded"
        />
        <button
          onClick={editingCreator ? handleEditCreator : handleCreateCreator}
          className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300"
        >
          {editingCreator ? "Update Creator" : "Add Creator"}
        </button>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setDeletingCreator(null);
        }}
        title="Confirm Deletion"
      >
        <p className="tw-mb-4">Are you sure you want to delete the creator "{deletingCreator?.firstName} {deletingCreator?.lastName}"?</p>
        <div className="tw-flex tw-justify-end tw-space-x-2">
          <button
            onClick={() => setShowDeleteModal(false)}
            className="tw-bg-gray-300 tw-text-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-gray-400 tw-transition-colors tw-duration-300"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteCreator}
            className="tw-bg-red-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-red-600 tw-transition-colors tw-duration-300"
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CreatorManagement;