import React, { useState } from 'react';
import { FaRobot, FaMagic, FaEdit, FaArrowLeft } from 'react-icons/fa';
import HeaderDesc from './HeaderDesc';
import { generateAiBlog } from "../../../services/cms/Service";
import { useNavigate } from "react-router-dom";

const CreateWithAI = ({ onEditAIContent, onBack }) => {
  const [aiPrompt, setAiPrompt] = useState('');
  const [aiGeneratedContent, setAiGeneratedContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(''); // Store edited content
  const navigate = useNavigate();



  const handleCreateWithAI = async () => {
    setIsLoading(true);
    const response = await generateAiBlog({
      message: `Please generate a response to the following question or topic. 
      Format the response using HTML tags to ensure it can be displayed properly on a web page. Use the following HTML elements where appropriate:

      - **Bold Text**: <strong>your text here</strong>
      - **Underlined Text**: <u>your text here</u>
      - **Line Breaks**: <br> (insert line breaks where appropriate)
      - **Paragraphs**: <p>your text here</p>
      - **Headings**: <h1>, <h2>, <h3>, etc. (for different heading levels)
      - **Lists**: 
          - Unordered lists: <ul><li>item</li></ul>
          - Ordered lists: <ol><li>item</li></ol>
      - **Links**: <a href="URL">your link text</a>
      - **Images**: <img src="image URL" alt="description">

      Use either a heading (e.g., <h1>) or a title at the beginning of the response, but not both, to maintain clarity.

      **Topic:** ${aiPrompt}?`
    });
    if (response?.data?.data?.text) {
      setAiGeneratedContent(response.data.data.text);
      setEditedContent(response.data.data.text); // Set edited content initially
      setIsLoading(false);
    }
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing); // Toggle between view and edit modes
  };

  const handleSaveEditedContent = () => {
    setAiGeneratedContent(editedContent); // Save the edited content
    setIsEditing(false); // Exit edit mode // Optionally pass back the updated content
  };



  const handleSubmit = () => {
    navigate('/content-management/add-post-ai', { state: { content: aiGeneratedContent } });
  };


  return (
    <>
      <HeaderDesc
        image={'https://media.licdn.com/dms/image/D5612AQFvJE3IlOdJOw/article-cover_image-shrink_720_1280/0/1681278919474?e=2147483647&v=beta&t=6rOv5oTouM2-Kd7TziGJM4VTgroCrSF0wxF0YtMuMpU'}
        desc={'Content Management Dashboard'}
      />
      <div className="tw-p-6 tw-mt-8 tw-bg-white tw-rounded-lg tw-shadow-xl tw-max-w-4xl tw-mx-auto tw-mb-16">
        <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-text-gray-800 tw-flex tw-items-center">
          <FaRobot className="tw-mr-3" /> Create with AI
        </h2>

        <div className="tw-bg-gray-100 tw-rounded-lg tw-shadow-xl hover:tw-shadow-2xl tw-transition-shadow tw-duration-300">
          <div className="tw-p-6">
            <p className="tw-text-lg tw-mb-4">Enter a topic or idea for your blog post:</p>
            <textarea
              placeholder="E.g., '10 tips for maintaining a healthy lifestyle'"
              className="tw-w-full tw-h-32 tw-mb-4 tw-p-2 tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-border-transparent"
              value={aiPrompt}
              onChange={(e) => setAiPrompt(e.target.value)}
            />
            <div className="tw-flex tw-justify-between">
              <button
                className="tw-px-6 tw-py-2 tw-border tw-border-gray-300 tw-rounded-lg tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 tw-transition tw-duration-300"
                onClick={onBack}
              >
                <FaArrowLeft className="tw-inline tw-mr-2" /> Back
              </button>
              <button
                className={`tw-px-6 tw-py-2 tw-bg-blue-600 tw-text-white tw-rounded-lg hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 tw-transition tw-duration-300 ${!aiPrompt || isLoading ? 'tw-opacity-50 tw-cursor-not-allowed' : ''
                  }`}
                onClick={handleCreateWithAI}
                disabled={!aiPrompt || isLoading}
              >
                {isLoading ? (
                  <span className="tw-flex tw-items-center">
                    <svg className="tw-animate-spin -tw-ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Generating...
                  </span>
                ) : (
                  <>
                    Generate Content <FaMagic className="tw-inline tw-ml-2" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {aiGeneratedContent && (
          <div className="tw-mt-8">
            <h3 className="tw-text-2xl tw-font-bold tw-mb-4 tw-text-gray-800">AI Generated Content</h3>

            {isEditing ? (
              // Editing Mode: Show Textarea for Editing
              <textarea
                className="tw-w-full tw-h-64 tw-p-4 tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-border-transparent"
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
              />
            ) : (
              // View Mode: Show the Generated Content
              <div
                className="tw-bg-gray-100 tw-p-6 tw-rounded-lg tw-shadow-inner"
                dangerouslySetInnerHTML={{ __html: aiGeneratedContent }} // Render HTML content
              />
            )}

            <div className="tw-mt-4 tw-flex tw-justify-between">
              <button
                className="tw-px-6 tw-py-2 tw-border tw-border-gray-300 tw-rounded-lg tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 tw-transition tw-duration-300"
                onClick={onBack}
              >
                <FaArrowLeft className="tw-inline tw-mr-2" /> Back
              </button>

              {isEditing ? (
                // Save Button: Save Edited Content
                <button
                  className="tw-px-6 tw-py-2 tw-bg-green-600 tw-text-white tw-rounded-lg hover:tw-bg-green-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-green-500 tw-transition tw-duration-300"
                  onClick={handleSaveEditedContent}
                >
                  <FaEdit className="tw-inline tw-mr-2" />Save Changes
                </button>
              ) : (
                // Edit Button: Toggle Edit Mode
                <>
                  <button
                    className="tw-px-6 tw-py-2 tw-bg-blue-600 tw-text-white tw-rounded-lg hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 tw-transition tw-duration-300"
                    onClick={toggleEditMode}
                  >
                    <FaEdit className="tw-inline tw-mr-2" /> Edit Content
                  </button>
                  <button
                    className="tw-px-6 tw-py-2 tw-bg-blue-600 tw-text-white tw-rounded-lg hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 tw-transition tw-duration-300"
                    onClick={handleSubmit}
                  >
                    <FaEdit className="tw-inline tw-mr-2" /> Use Content
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateWithAI;