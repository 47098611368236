import React, { useEffect, useState } from "react";
import "./ManageCorporates.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ManageCorporatesList from "./component/ManageCorporatesList";
import { getAllCorporates } from "../../services/corporatewellness/Service";

function ManageCorporates() {
  const [allCorporateData, setAllCorporateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered data
  const [currentData, setCurrentData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 18;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchCorporates();
  }, []);

  useEffect(() => {
    paginateData();
  }, [currentPage, filteredData]);

  const fetchCorporates = async () => {
    try {
      const res = await getAllCorporates(-1);

      if (res.data.status === 200) {
        setAllCorporateData(res.data.data);
        filterData(res.data.data, searchQuery);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterData = (data, query) => {
    if (query.trim() === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) =>
        item.corporateName.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    }
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterData(allCorporateData, query);
  };

  const paginateData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const paginatedData = filteredData.slice(startIndex, endIndex);
    setCurrentData(paginatedData);
    setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleBackButton = () => {
    navigate("/manage-corporates");
  };

  return (
    <>
      <section className="ManageCorporates ">
        <div className="container-fluid px-0 mb-3">
          {/* <div className="row align-items-center pb-3 sticky-top-bar">
            <div
              className="col-md"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            > */}
          {/* <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button> */}
          {/* <h1 className="main-title" style={{ margin: "0" }}>
                Manage Corporates
              </h1>
            </div> */}
          {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" data-bs-toggle="modal" data-bs-target="#testManageCorporates">Test Blocker PopUps</Link></div> */}
          {/* </div> */}

          <div className="manage-corporates p-3 mb-3">
            <div className="row mb-3">
              <div className="col-md d-flex">
                <div className="form-group" style={{ width: "100%" }}>
                  <input
                    type="text"
                    placeholder="Search By Corporate"
                    className="form-control input-field-ui ps-5"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <img
                    src={require("../../images/icon-search.png")}
                    className="input-icon-seacrh"
                    alt=""
                  />
                </div>
              </div>
              {/* <div className='col-md-5 px-0'>
                        <select
                            className='form-select input-field-ui'>
                            <option value="All">--Select Corporate--</option>
                            <option value="Call">1</option>
                            <option value="Whatsapp">2</option>
                            <option value="SMS">3</option>
                            <option value="Email">4</option>
                            <option value="Push Notification">5</option>
                        </select>
                    </div> */}
              <div className="col-md-auto">
                <button className="btn btn-primary btn-primary clear-search-btn">
                  Submit
                </button>
              </div>
            </div>

            <div className="row">
              <ManageCorporatesList corporatesData={currentData} />
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col">
                <h1 className="dashboard-corpwell-title mb-0">
                  Showing {currentPage} to {totalPages} of {totalPages} entries
                </h1>
              </div>
              <div className="col-auto text-end">
                <div className="dashboard-corpwell-title mb-0">
                  {currentPage !== 1 && (
                    <button
                      onClick={handlePrevious}
                      // disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  )}{" "}
                  <span className="px-3">{currentPage}</span>{" "}
                  {currentPage < totalPages && (
                    <button
                      onClick={handleNext}
                      // disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ManageCorporates;
