import React, { useEffect, useState } from "react";
import "./ChallengeReport.css";
import { Link } from "react-router-dom";
import {
  generateChallengeReport,
  getAllChallenges,
  getAllCorporates,
} from "../../services/corporatewellness/Service";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #DCDCDC !important",
    padding: "5px 7px",
    borderRadius: "12px !important",
    width: "100% !important",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(59, 130, 246, 1)" // Tailwind's bg-blue-500
      : state.isFocused
      ? "rgba(59, 130, 246, 0.1)" // bg-blue-100 on focus
      : "white",
    color: state.isSelected ? "white" : "black",
    padding: "0.5rem 1rem", // Tailwind's p-2 px-4
  }),
  menu: (base) => ({
    ...base,
    borderRadius: "0.375rem", // Tailwind's rounded-md
    marginTop: "0.25rem", // Tailwind's mt-1
    backgroundColor: "white", // Tailwind's bg-white
  }),
  menuList: (base) => ({
    ...base,
    padding: "0", // Tailwind's p-0
  }),
  singleValue: (base) => ({
    ...base,
    color: "black", // Tailwind's text-black
  }),
};

function ChallengeReport() {
  const [challengeId, setChallengeId] = useState(0);

  const [isCorporateClearable, setIsCorporateClearable] = useState(true);
  const [isCorporateSearchable, setIsCorporateSearchable] = useState(true);
  const [isChallengeClearable, setIsChallengeClearable] = useState(true);
  const [isChallengeSearchable, setIsChallengeSearchable] = useState(true);
  const [corporatesData, setCorporatesData] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState(null);
  const [challengesData, setChallengesData] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [reportData, setReportData] = useState({
    // startDate: "",
    // endDate: "",
    reportType: "",
    corporateId: "",
    // challengeId: "",
    reportingEmail: "",
  });

  useEffect(() => {
    fetchCorporates();
  }, []);

  useEffect(() => {
    if (selectedCorporate && Object.values(selectedCorporate).length > 0) {
      fetchChallenges();
    }
  }, [selectedCorporate]);

  const fetchCorporates = async () => {
    try {
      const res = await getAllCorporates(-1);

      if (res.data.status === 200) {
        const data = res.data.data.map((d) => ({
          value: d.corporateId,
          label: `${d.corporateName} - ${d.corporateId}`,
        }));
        setCorporatesData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChallenges = async () => {
    try {
      const payload = {
        corporateId: selectedCorporate.value,
      };

      const res = await getAllChallenges(payload);

      if (res.data.status === 200) {
        const data = res.data.data.map((d) => ({
          value: d.challengeId,
          label: `${d.challengeTitle} - ${d.challengeId}`,
        }));
        setChallengesData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCorporateSelectChange = (selectedOption) => {
    if (selectedOption) {
      setReportData((prevData) => ({
        ...prevData,
        corporateId: selectedOption.value,
      }));
    } else {
      setReportData((prevData) => ({
        ...prevData,
        corporateId: "",
      }));
    }

    setSelectedCorporate(selectedOption);
  };

  const handleChallengeSelectChange = (selectedOption) => {
    if (selectedOption) {
      setReportData((prevData) => ({
        ...prevData,
        challengeId: selectedOption.value,
      }));
    } else {
      setReportData((prevData) => ({
        ...prevData,
        challengeId: "",
      }));
    }

    setSelectedChallenge(selectedOption);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setReportData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generateReport = async () => {
    try {
      let error = false;
      if (Object.values(reportData).every(d => d === "")) {
        error = true;
      } else if (Object.values(reportData).length > 0 && reportData.reportType !== "app_usage_report" && Object.values(reportData).some(d => d === "")) {
        error = true;
      } else if (reportData.reportType === "app_usage_report" && (reportData.corporateId === "" ||reportData.reportingEmail === "")) {
        error = true;
      }

      if (error) {
        toast.error("Please enter all the required details.");
        return;
      }

      // console.log("validated");

      const formData = new FormData();
      formData.append("formData", JSON.stringify(reportData));
      formData.append("file", new File([], "empty.jpg"));

      const resp = await generateChallengeReport(formData);

      if (resp.data.status === 200) {
        toast.success(resp.data.message);
      } else {
        console.log(resp.data.error);
        
      }
      
    } catch (error) {
      console.log(error);
      
    }
  }

  console.log(reportData);

  return (
    <>
      <ToastContainer />
      <section className="ChallengeReport">
        <div className="container-fluid px-0 mb-3">
          {/* <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Challenge Report</h1>
            </div>
          </div> */}

          {/* <div className='challenge-report mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/37543_1726209216.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo. Vivamus et interdum felis, ut vulputate tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo.</p>
              </div>
            </div>
          </div> */}

          {/* <div className='challenge-report p-4'> */}

          <div className="row">
            <div className="col-md">
              <label className="form-label sub-title">Select Report</label>
              <select
                className="form-select input-field-ui "
                id=""
                aria-label="Default select example"
                name="reportType"
                value={reportData.reportType}
                onChange={handleChange}
              >
                <option value="All">Select Report</option>
                <option value="steps">Steps</option>
                {/* <option value="Steps Milestone">Steps Milestone</option> */}
                <option value="distance">Distance</option>
                <option value="calories">Calories</option>
                <option value="water">Water</option>
                <option value="food">Food</option>
                {/* <option value="Karma Earned">Karma Earned</option>
                <option value="Karma Donated">Karma Donated</option> */}
                <option value="activity">Activity</option>
                {/* <option value="Team Engagement Report">
                  Team Engagement Report
                </option> */}
                {/* <option value="onboarding_status_report">
                  Onboarding Status Report
                </option> */}
                {/* <option value="Self Challenge Report">
                  Self Challenge Report
                </option> */}
                <option value="app_usage_report">App Usage Report</option>
              </select>
            </div>
            <div className="col-md">
              <label className="form-label sub-title">Select Corporate</label>
              <Select
                isClearable={isCorporateClearable}
                isSearchable={isCorporateSearchable}
                name="color1"
                options={corporatesData}
                value={selectedCorporate}
                onChange={handleCorporateSelectChange}
                styles={customStyles}
              />
            </div>

            {reportData.reportType !== "app_usage_report" && (
              <div className="col-md">
                <label className="form-label sub-title">Select Challenge</label>
                <Select
                  isClearable={isChallengeClearable}
                  isSearchable={isChallengeSearchable}
                  name="color2"
                  options={challengesData}
                  value={selectedChallenge}
                  onChange={handleChallengeSelectChange}
                  styles={customStyles}
                  isDisabled={selectedCorporate === null}
                />
              </div>
            )}
            <div className="col-md">
              <label className="form-label sub-title">Reporting Email</label>
              <input
                type="email"
                className="form-control input-field-ui"
                id=""
                placeholder="Enter your Email Id"
                name="reportingEmail"
                value={reportData.reportingEmail}
                onChange={handleChange}
              />
            </div>
          </div>
          <br />
          {reportData.reportType !== "app_usage_report" && (
            <div className="row">
              <div className="col-3">
                <label className="form-label sub-title">Start Date</label>
                <input
                  type="date"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="Start Date"
                  name="startDate"
                  value={reportData.startDate}
                  onChange={handleChange}
                />
              </div>
              <div className="col-3">
                <label className="form-label sub-title">End Date</label>
                <input
                  type="date"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="End Date"
                  name="endDate"
                  value={reportData.endDate}
                  onChange={handleChange}
                  min={reportData.startDate}
                />
              </div>
            </div>
          )}

          <div className="row mt-3">
            <div className="col-md-7">
              <button type="button" className="btn program-save-btn me-2" onClick={generateReport}>
                Generate
              </button>{" "}
              <button
                type="button"
                className="btn clear-btn"
                onClick={() => {
                  const d = Object.keys(reportData).reduce((acc, key) => {
                    acc[key] = "";
                    return acc;
                  }, {});
                  setReportData(d);
                  setSelectedCorporate(null);
                  setSelectedChallenge(null);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
}

export default ChallengeReport;
