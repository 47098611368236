import React, { useState } from 'react';
import './CorporateChallenges.css';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CorporateChallengesTable from './component/CorporateChallengesTable';
import ExistingChallengesTable from './component/ExistingChallengesTable';
import LeaderboardTable from './component/LeaderboardTable';
import ChallengeReport from './ChallengeReport';
import ChallengeReportTable from './component/ChallengeReportTable';




function CorporateChallenges() {


  return (
    <>
      <section className='CorporateChallenges'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Corporate Challenges</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-challenges">Create Challenge</Link></div> */}
          </div>

          <div className='corporate-challenges mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/5641_1726204058.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">The Challenges Leaderboard Page provides a centralized view of all ongoing and completed corporate wellness challenges. This page allows administrators to easily track the progress of various challenges and see how individuals or teams are performing in real-time.</p>
              </div>
            </div>
          </div>

          <div className='corporate-challenges'>
            
            <div className="row">
                <div className="col-md-12">
                      <Tabs>
                        <TabList>
                          <Tab>Corporate Challenges</Tab>
                          {/* <Tab>Existing Challenges</Tab>
                          <Tab>Leaderboard</Tab> */}
                          <Tab>Challenge Report</Tab>
                        </TabList>

                        <TabPanel>
                          <div className="table-responsive tableCorporateChallenges">
                              <CorporateChallengesTable/>
                          </div>
                        </TabPanel>

                        {/* <TabPanel>
                          <div className="table-responsive tableExistingChallenges">
                              <ExistingChallengesTable/>
                          </div>
                        </TabPanel> */}

                        {/* <TabPanel>
                            <div className="table-responsive tableLeaderboard">
                              <LeaderboardTable/>
                            </div>
                        </TabPanel> */}

                        <TabPanel>
                           <ChallengeReport/>
                           <hr className='my-4'/>
                           <h1 className="dashboard-corpwell-title mb-3">Ready Reports:</h1>
                           <div className="table-responsive tableChallengeReport">
                            
                              <ChallengeReportTable/>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
}

export default CorporateChallenges;
