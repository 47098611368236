import React, { useState } from 'react';
import { SiTwilio, SiWhatsapp } from 'react-icons/si';
import { FaRobot } from 'react-icons/fa';

const whatsappServices = [
  { 
    name: 'Twilio', 
    icon: SiTwilio, 
    description: 'WhatsApp platform',
    subDescription: 'Send reports to patients, doctors, and clients'
  },
  { 
    name: 'WhatsApp Business API by Brevo', 
    icon: SiWhatsapp, 
    description: 'WhatsApp platform',
    subDescription: 'Send reports to patients, doctors, and clients'
  },
  { 
    name: 'Interakt', 
    icon: FaRobot, 
    description: 'WhatsApp platform',
    subDescription: 'Send reports to patients, doctors, and clients'
  }
];

const WhatsappCard = ({ name, Icon, description, subDescription, isActive, onClick }) => (
  <div 
    className={`tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-md tw-transition-all tw-duration-300 hover:tw-shadow-lg tw-cursor-pointer
      ${isActive ? 'tw-border-2 tw-border-[#03335b] tw-bg-[#03335b]/10' : 'tw-border tw-border-gray-200'}`}
    onClick={onClick}
  >
    <div className="tw-flex tw-items-start">
      <div className="tw-w-20 tw-h-20 tw-bg-gray-100 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-mr-4 tw-flex-shrink-0">
        <Icon className="tw-text-[#03335b] tw-text-5xl" />
      </div>
      <div>
        <h3 className="tw-text-lg tw-font-bold tw-text-[#03335b] tw-mb-2">{name}</h3>
        <p className="tw-text-sm tw-text-gray-600 tw-mb-1">{description}</p>
        <p className="tw-text-sm tw-text-gray-600">{subDescription}</p>
      </div>
    </div>
  </div>
);

const Whatsapp = () => {
  const [activeService, setActiveService] = useState(0); // Default to Twilio (index 0)

  return (
    <div className="tw-bg-white tw-rounded-lg tw-p-8 tw-m-4 tw-shadow-lg">
      <p className="tw-text-gray-600 tw-mb-6">
        Integrating WhatsApp services helps the platform send messages to users for various communications, including reports and notifications.
      </p>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6">
        {whatsappServices.map((service, index) => (
          <WhatsappCard
            key={service.name}
            name={service.name}
            Icon={service.icon}
            description={service.description}
            subDescription={service.subDescription}
            isActive={index === activeService}
            onClick={() => setActiveService(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Whatsapp;