import React, { act, useEffect, useState } from "react";
import "./AddNewCorporate.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { createCorporate, fetchCorporate, updateCorporate } from "../../services/corporatewellness/Service";

function AddNewCorporate() {
  const { id } = useParams();
  const routeName = useLocation();

  const action = routeName.pathname.includes("edit") ? "edit" : "create";

  const [corporateBasicData, setCorporateBasicData] = useState({
    corporateName: "",
    corporateLogo: "",
    engagementStart: "",
    engagementEnd: "",
    expectedUsers: "",
    emailDomain: "",
    planType: "",
    planDuration: "",
    subscriptionDuration: "",
  });
  const [corporateSettingData, setCorporateSettingData] = useState({
    program: "",
    logo: "",
    zoneManagerDashboardTitle: "",
    sessionTimeout: "",
    insurance: "",
    proClasses: "",
    liveCoaching: "",
    accountCreationIdentifier: "",
    reportUniqueIdentifier: "",
  });
  const [corporateFeatureData, setCorporateFeatureData] = useState({
    remotemonitoring: false,
    insurance: false,
    communications: false,
    bulkuserupload: false,
    usermanagement: false,
    changepassword: false,
    friendchallenge: false,
  });
  const [corporateAdminData, setCorporateAdminData] = useState({
    adminName: "",
    adminEmail: "",
    adminPassword: "",
  });

  const [corporateBasicErrorData, setCorporateBasicErrorData] = useState({
    corporateName: "",
    corporateLogo: "",
    engagementStart: "",
    engagementEnd: "",
    expectedUsers: "",
    emailDomain: "",
    planType: "",
    planDuration: "",
    subscriptionDuration: "",
  });
  const [corporateSettingErrorData, setCorporateSettingErrorData] = useState({
    program: "",
    logo: "",
    zoneManagerDashboardTitle: "",
    sessionTimeout: "",
    insurance: "",
    proClasses: "",
    liveCoaching: "",
    accountCreationIdentifier: "",
    reportUniqueIdentifier: "",
  });
  const [corporateAdminErrorData, setCorporateAdminErrorData] = useState({
    adminName: "",
    adminEmail: "",
    adminPassword: "",
  });

  const [corporateDataError, setCorporateDataError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (action === 'edit') {
        fetchCorporateData(id);
    }
  }, [id]);

  const fetchCorporateData = async (id) => {
    setIsLoading(true);
    try {
        const payload = {
          corporateId: id,
          type: "single"
        }
        const response = await fetchCorporate(payload);

        if (response.data.status === 200) {
          setCorporateBasicData(response.data.data.corporateBasicData);
          setCorporateSettingData(response.data.data.corporateSettingData);
          setCorporateFeatureData(response.data.data.corporateFeatureData);
        }

        setIsLoading(false);
    } catch(e) {
        setIsLoading(false);
        console.log(e);
    }
  }

  const handleBackButton = () => {
    if (action === "create") {
      navigate("/corporate-dashboard");
    } else if (action === "edit") {
      navigate(`/corporates-detail/${id}`);
    }
  };

  const handleInputs = (e, type) => {
    const name = e.target.name;
    const value = e.target.value;

    if (
      name === "planDuration" ||
      name === "expectedUsers" ||
      name === "proClasses" ||
      name === "sessionTimeout"
    ) {
      if (value < 0) {
        return;
      }
    }

    if (type === "basic") {
      setCorporateBasicData((prev) => ({
        ...prev,
        [name]: value,
      }));

      setCorporateBasicErrorData((prev) => ({
        ...prev,
        [name]: "",
      }));

      if (value === "") {
        setCorporateBasicErrorData((prev) => ({
          ...prev,
          [name]: "This is required field.",
        }));
      }
    } else if (type === "setting") {
      setCorporateSettingData((prev) => ({
        ...prev,
        [name]: value,
      }));

      setCorporateSettingErrorData((prev) => ({
        ...prev,
        [name]: "",
      }));

      if (value === "") {
        setCorporateSettingErrorData((prev) => ({
          ...prev,
          [name]: "This is required field.",
        }));
      }
    } else if (type === "feature") {
      setCorporateFeatureData((prev) => ({
        ...prev,
        [name]: e.target.checked,
      }));
    } else if (type === "admin") {
      setCorporateAdminData((prev) => ({
        ...prev,
        [name]: value,
      }));

      setCorporateAdminErrorData((prev) => ({
        ...prev,
        [name]: "",
      }));

      if (value === "") {
        setCorporateAdminErrorData((prev) => ({
          ...prev,
          [name]: "This is required field.",
        }));
      }
    }
  };

  const handleFileChange = (e, type) => {
    const name = e.target.name;
    const fileInput = e.target;
    const file = fileInput.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          if (
            (img.width >= 350 && img.width <= 450) ||
            (img.height >= 350 && img.height <= 450)
          ) {
            if (type === "basic") {
              setCorporateBasicData((prevData) => ({
                ...prevData,
                [name]: file,
              }));

              setCorporateBasicErrorData((prev) => ({
                ...prev,
                [name]: "",
              }));
            } else if (type === "setting") {
              setCorporateSettingData((prevData) => ({
                ...prevData,
                [name]: file,
              }));

              setCorporateSettingErrorData((prev) => ({
                ...prev,
                [name]: "",
              }));
            }
          } else {
            toast.error("Image dimensions must be 400x400.");
            fileInput.value = "";
          }
        };

        img.onerror = () => {
          toast.error("Failed to load the image. Please try a different file.");
          fileInput.value = "";
        };
      };

      reader.readAsDataURL(file);
    } else {
      toast.error("Please select a valid image file.");
      fileInput.value = "";
    }
  };

  console.log(corporateBasicData);
  console.log(corporateSettingData);
  console.log(corporateFeatureData);
  console.log(corporateAdminData);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (
        Object.values(corporateBasicData).every((d) => d === "") ||
        Object.values(corporateBasicData).some((d) => d === "") ||
        Object.values(corporateBasicErrorData).some((d) => d !== "") ||
        Object.values(corporateSettingData).every((d) => d === "") ||
        Object.values(corporateSettingData).some((d) => d === "") ||
        Object.values(corporateSettingErrorData).some((d) => d !== "") ||
        (action === "create" && 
          (Object.values(corporateAdminData).every((d) => d === "") ||
          Object.values(corporateAdminData).some((d) => d === "") ||
          Object.values(corporateAdminErrorData).some((d) => d !== ""))
        )
      ) {
        const updatedBasicErrors = Object.keys(corporateBasicErrorData).reduce(
          (acc, key) => {
            acc[key] =
              corporateBasicData[key] === "" ? "This is required field." : "";
            return acc;
          },
          {}
        );

        const updatedSettingErrors = Object.keys(
          corporateSettingErrorData
        ).reduce((acc, key) => {
          acc[key] =
            corporateSettingData[key] === "" ? "This is required field." : "";
          return acc;
        }, {});

        if (action === "create") {
          const updatedAdminErrors = Object.keys(corporateAdminErrorData).reduce(
            (acc, key) => {
              acc[key] =
                corporateAdminData[key] === "" ? "This is required field." : "";
              return acc;
            },
            {}
          );

          setCorporateAdminErrorData(updatedAdminErrors);
        }

        setCorporateBasicErrorData(updatedBasicErrors);
        setCorporateSettingErrorData(updatedSettingErrors);
        return;
      }

      const formData = new FormData();

      formData.append("corporateBasicData", JSON.stringify(corporateBasicData));
      formData.append(
        "corporateSettingData",
        JSON.stringify(corporateSettingData)
      );
      formData.append(
        "corporateFeatureData",
        JSON.stringify(corporateFeatureData)
      );
      formData.append("corporateAdminData", JSON.stringify(corporateAdminData));

      if (
        corporateBasicData.corporateLogo !== "" &&
        corporateBasicData.corporateLogo instanceof File
      ) {
        formData.append("corporateLogo", corporateBasicData.corporateLogo);
      } else {
        formData.append("corporateLogo", new File([], "empty.jpg"));
      }

      if (
        corporateSettingData.logo !== "" &&
        corporateSettingData.logo instanceof File
      ) {
        formData.append("logo", corporateSettingData.logo);
      } else {
        formData.append("logo", new File([], "empty.jpg"));
      }

      if (action === "edit") {
        await updateCorporate(formData)
          .then((res) => {
            if (res.data.status === 200) {
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 1000,
                onClose: () => {
                  setIsSubmitting(false);
                  navigate("/corporate-dashboard");
                },
              });
            } else {
              if (res.data.status === 403) {
                setIsSubmitting(false);
              }
              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 1000,
              });
            }
          })
          .catch((err) => {
            // toast.error(err.data.message, {
            //   position: 'top-right',
            //   autoClose: 1000,
            // });
            console.log(err);
          });
      } else {
        await createCorporate(formData)
          .then((res) => {
            if (res.data.status === 200) {
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 1000,
                onClose: () => {
                  setIsSubmitting(false);
                  navigate("/corporate-dashboard");
                },
              });
            } else {
              if (res.data.status === 403) {
                setIsSubmitting(false);
              }
              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 1000,
              });
            }
          })
          .catch((err) => {
            // toast.error(err.data.message, {
            //   position: 'top-right',
            //   autoClose: 1000,
            // });
            console.log(err);
          });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <section className="AddNewCorporate ">
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div
              className="col-md"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-primary back-btn mb-0 me-3"
                onClick={handleBackButton}
              >
                <i className="bi bi-chevron-left me-1"></i> Back
              </button>
              <h1 className="main-title" style={{ margin: "0" }}>
                {action === "create" ? "Add New Corporate" : "Edit Corporate"}
              </h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" data-bs-toggle="modal" data-bs-target="#testAddNewCorporate">Test Blocker PopUps</Link></div> */}
          </div>

          <div className="add-new-corporate p-4">
            <div className="row">
              <div className="col-md-7 mb-4">
                <label className="form-label sub-title">
                  Corporate Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="Enter Corporate Name"
                  name="corporateName"
                  value={corporateBasicData.corporateName}
                  onChange={(e) => handleInputs(e, "basic")}
                  disabled={action === "edit"}
                />
                {corporateBasicErrorData.corporateName && (
                  <div className="text-danger">
                    {corporateBasicErrorData.corporateName}
                  </div>
                )}
              </div>

              <div className="col-md-7 mb-4">
                <div className="row">
                  <label className="form-label sub-title">
                    Corporate Logo Url <span className="text-danger">*</span>
                  </label>
                  <div className="col pe-1">
                    <input
                      type="file"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="Enter Corporate Logo Url"
                      name="corporateLogo"
                      onChange={(e) => handleFileChange(e, "basic")}
                    />
                  </div>
                  {/* <div className="col-auto ps-0">
                    <button
                      type="button"
                      id=""
                      className="btn btn-success uplaod-file-btn"
                    >
                      Upload File
                    </button>
                  </div> */}
                  <label className="form-label mb-0 mt-2 sub-title-1">
                    Dimension:- (Width: 400 px x Height: 400 px)
                  </label>
                  {corporateBasicErrorData.corporateLogo && (
                    <div className="text-danger">
                      {corporateBasicErrorData.corporateLogo}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-7 mb-4">
                <div className="row">
                  <label className="form-label sub-title">
                    Corporate Dashboard Logo{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col pe-1">
                    <input
                      type="file"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="Enter Corporate Dashboard Logo"
                      name="logo"
                      onChange={(e) => handleFileChange(e, "setting")}
                    />
                  </div>
                  {/* <div className="col-auto ps-0">
                    <button
                      type="button"
                      id=""
                      className="btn btn-success uplaod-file-btn"
                    >
                      Upload File
                    </button>
                  </div> */}
                  <label className="form-label mb-0 mt-2 sub-title-1">
                    Dimension:- (Width: 400 px x Height: 400 px)
                  </label>
                  {corporateSettingErrorData.logo && (
                    <div className="text-danger">
                      {corporateSettingErrorData.logo}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-7 mb-4">
                <label className="form-label sub-title">
                  Engagement Program Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="Enter Engagement Program Name"
                  name="program"
                  value={corporateSettingData.program}
                  onChange={(e) => handleInputs(e, "setting")}
                />
                {corporateSettingErrorData.program && (
                  <div className="text-danger">
                    {corporateSettingErrorData.program}
                  </div>
                )}
              </div>

              <div className="col-md-7 mb-4">
                <div className="row">
                  <div className="col-md">
                    <label className="form-label sub-title">
                      Engagement Program Start Date{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="Start Date"
                      name="engagementStart"
                      value={corporateBasicData.engagementStart}
                      onChange={(e) => handleInputs(e, "basic")}
                    />
                    {corporateBasicErrorData.engagementStart && (
                      <div className="text-danger">
                        {corporateBasicErrorData.engagementStart}
                      </div>
                    )}
                  </div>
                  <div className="col-md">
                    <label className="form-label sub-title">
                      Engagement Program End Date{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="End Date"
                      name="engagementEnd"
                      value={corporateBasicData.engagementEnd}
                      onChange={(e) => handleInputs(e, "basic")}
                      min={corporateBasicData.engagementStart}
                    />
                    {corporateBasicErrorData.engagementEnd && (
                      <div className="text-danger">
                        {corporateBasicErrorData.engagementEnd}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {action === "create" &&
              <>
                <div className="col-md-7 mb-4">
                  <label className="form-label sub-title">
                    Base Subscription <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select input-field-ui "
                    name="subscriptionDuration"
                    value={corporateBasicData.subscriptionDuration}
                    onChange={(e) => handleInputs(e, "basic")}
                  >
                    <option value="">Select Subscription</option>
                    <option value="12 months">12 Months</option>
                    <option value="6 months">6 Months</option>
                    <option value="3 months">3 Months</option>
                  </select>
                </div>

                <div className="col-md-7 mb-4">
                  <label className="form-label sub-title">
                    Plan Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select input-field-ui "
                    name="planType"
                    value={corporateBasicData.planType}
                    onChange={(e) => handleInputs(e, "basic")}
                  >
                    <option value="">Select Plan Type</option>
                    <option value="1">
                      Plan Type 1 (Human Coach + Doc + Device Link + Expert)
                    </option>
                  </select>
                  {corporateBasicErrorData.planType && (
                    <div className="text-danger">
                      {corporateBasicErrorData.planType}
                    </div>
                  )}
                </div>

                <div className="col-md-7 mb-4">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-label sub-title">
                        Plan Duration <span className="text-danger">*</span>
                      </label>
                    </div>

                    <div className="col-md-12">
                      <div className="row align-items-center">
                        <div className="col-md">
                          <input
                            type="number"
                            className="form-control input-field-ui"
                            id=""
                            placeholder="000"
                            name="planDuration"
                            value={corporateBasicData.planDuration}
                            onChange={(e) => handleInputs(e, "basic")}
                          />
                        </div>
                        <div className="col-md-auto ps-0">
                          <label className="form-label mb-0">Months</label>
                        </div>
                        {corporateBasicErrorData.planDuration && (
                          <div className="text-danger">
                            {corporateBasicErrorData.planDuration}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
              }

              <div className="col-md-7 mb-4">
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label sub-title">
                      Users Counts Expected{" "}
                      <span className="text-danger">*</span>
                    </label>
                  </div>

                  <div className="col-md-12">
                    <div className="row align-items-center">
                      <div className="col-md">
                        <input
                          type="number"
                          className="form-control input-field-ui"
                          id=""
                          placeholder="000"
                          name="expectedUsers"
                          value={corporateBasicData.expectedUsers}
                          onChange={(e) => handleInputs(e, "basic")}
                        />
                      </div>
                      <div className="col-md-auto ps-0">
                        <label className="form-label mb-0">Count</label>
                      </div>
                      {corporateBasicErrorData.expectedUsers && (
                        <div className="text-danger">
                          {corporateBasicErrorData.expectedUsers}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {action === "create" && 
              <>
                <div className="col-md-7 mb-4">
                  <div className="mb-2">
                    <label className="form-check-label sub-title">
                      Insurance* <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      id="templateRadioGroup1"
                      type="radio"
                      // defaultChecked
                      name="insurance"
                      value="yes"
                      checked={corporateSettingData.insurance === "yes"}
                      onChange={(e) => handleInputs(e, "setting")}
                    />
                    <label
                      htmlFor="templateRadioGroup1"
                      className="form-check-label pt-1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      id="templateRadioGroup2"
                      type="radio"
                      name="insurance"
                      value="no"
                      checked={corporateSettingData.insurance === "no"}
                      onChange={(e) => handleInputs(e, "setting")}
                    />
                    <label
                      htmlFor="templateRadio2"
                      className="form-check-label pt-1"
                    >
                      No
                    </label>
                  </div>
                  {corporateSettingErrorData.insurance && (
                    <div className="text-danger">
                      {corporateSettingErrorData.insurance}
                    </div>
                  )}
                </div>

                <div className="col-md-7 mb-4">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-label sub-title">
                        Pro Classes <span className="text-danger">*</span>
                      </label>
                    </div>

                    <div className="col-md-12">
                      <div className="row align-items-center">
                        <div className="col-md">
                          <input
                            type="number"
                            className="form-control input-field-ui"
                            id=""
                            placeholder="000"
                            name="proClasses"
                            value={corporateSettingData.proClasses}
                            onChange={(e) => handleInputs(e, "setting")}
                          />
                        </div>
                        <div className="col-md-auto ps-0">
                          <label className="form-label mb-0">Count</label>
                        </div>
                      </div>
                      {corporateSettingErrorData.proClasses && (
                        <div className="text-danger">
                          {corporateSettingErrorData.proClasses}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-7 mb-4">
                  <div className="mb-2">
                    <label className="form-check-label sub-title">
                      Live Coaching <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      id="templateRadioGroup3"
                      type="radio"
                      // defaultChecked
                      name="liveCoaching"
                      value="yes"
                      checked={corporateSettingData.liveCoaching === "yes"}
                      onChange={(e) => handleInputs(e, "setting")}
                    />
                    <label
                      htmlFor="templateRadioGroup3"
                      className="form-check-label pt-1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      id="templateRadioGroup4"
                      type="radio"
                      name="liveCoaching"
                      value="no"
                      checked={corporateSettingData.liveCoaching === "no"}
                      onChange={(e) => handleInputs(e, "setting")}
                    />
                    <label
                      htmlFor="templateRadioGroup4"
                      className="form-check-label pt-1"
                    >
                      No
                    </label>
                  </div>
                  {corporateSettingErrorData.liveCoaching && (
                    <div className="text-danger">
                      {corporateSettingErrorData.liveCoaching}
                    </div>
                  )}
                </div>

                <div className="col-md-7 mb-4">
                  <label className="form-label sub-title">
                    Admin Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control input-field-ui"
                    id=""
                    placeholder="Enter Admin Name"
                    name="adminName"
                    value={corporateAdminData.adminName}
                    onChange={(e) => handleInputs(e, "admin")}
                  />
                  {corporateAdminErrorData.adminName && (
                    <div className="text-danger">
                      {corporateAdminErrorData.adminName}
                    </div>
                  )}
                </div>

                <div className="col-md-7 mb-4">
                  <label className="form-label sub-title">
                    Admin Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control input-field-ui"
                    id=""
                    placeholder="Enter Admin Email"
                    name="adminEmail"
                    value={corporateAdminData.adminEmail}
                    onChange={(e) => handleInputs(e, "admin")}
                  />
                  {corporateAdminErrorData.adminEmail && (
                    <div className="text-danger">
                      {corporateAdminErrorData.adminEmail}
                    </div>
                  )}
                </div>

                <div className="col-md-7 mb-4">
                  <label className="form-label sub-title">
                    Admin Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control input-field-ui"
                    id=""
                    placeholder="Enter Admin Password"
                    name="adminPassword"
                    value={corporateAdminData.adminPassword}
                    onChange={(e) => handleInputs(e, "admin")}
                  />
                  {corporateAdminErrorData.adminPassword && (
                    <div className="text-danger">
                      {corporateAdminErrorData.adminPassword}
                    </div>
                  )}
                </div>
              </>
              }

              <div className="col-md-7 mb-4">
                <div className="mb-2">
                  <label className="form-check-label sub-title">
                    Features Allowed
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    id="templateCheckboxGroup1"
                    type="checkbox"
                    name="remotemonitoring"
                    checked={corporateFeatureData.remotemonitoring}
                    onChange={(e) => handleInputs(e, "feature")}
                  />
                  <label
                    htmlFor="templateCheckboxGroup1"
                    className="form-check-label pt-1"
                  >
                    Remote Monitoring
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    id="templateCheckboxGroup2"
                    type="checkbox"
                    name="insurance"
                    checked={corporateFeatureData.insurance}
                    onChange={(e) => handleInputs(e, "feature")}
                  />
                  <label
                    htmlFor="templateCheckboxGroup2"
                    className="form-check-label pt-1"
                  >
                    Insurance
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    id="templateCheckboxGroup3"
                    type="checkbox"
                    name="communications"
                    checked={corporateFeatureData.communications}
                    onChange={(e) => handleInputs(e, "feature")}
                  />
                  <label
                    htmlFor="templateCheckboxGroup3"
                    className="form-check-label pt-1"
                  >
                    Communnication
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    id="templateCheckboxGroup4"
                    type="checkbox"
                    name="bulkuserupload"
                    checked={corporateFeatureData.bulkuserupload}
                    onChange={(e) => handleInputs(e, "feature")}
                  />
                  <label
                    htmlFor="templateCheckboxGroup4"
                    className="form-check-label pt-1"
                  >
                    Bulk User Upload
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    id="templateCheckboxGroup5"
                    type="checkbox"
                    name="usermanagement"
                    checked={corporateFeatureData.usermanagement}
                    onChange={(e) => handleInputs(e, "feature")}
                  />
                  <label
                    htmlFor="templateCheckboxGroup5"
                    className="form-check-label pt-1"
                  >
                    User Management
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    id="templateCheckboxGroup6"
                    type="checkbox"
                    name="changepassword"
                    checked={corporateFeatureData.changepassword}
                    onChange={(e) => handleInputs(e, "feature")}
                  />
                  <label
                    htmlFor="templateCheckboxGroup6"
                    className="form-check-label pt-1"
                  >
                    Change Password
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    id="templateCheckboxGroup7"
                    type="checkbox"
                    name="friendchallenge"
                    checked={corporateFeatureData.friendchallenge}
                    onChange={(e) => handleInputs(e, "feature")}
                  />
                  <label
                    htmlFor="templateCheckboxGroup7"
                    className="form-check-label pt-1"
                  >
                    Friend Challenge
                  </label>
                </div>
              </div>

              <div className="col-md-7 mb-4">
                <label className="form-label sub-title">
                  Zone Manager Dashboard Title{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="Enter Zone Manager Dashboard Title"
                  name="zoneManagerDashboardTitle"
                  value={corporateSettingData.zoneManagerDashboardTitle}
                  onChange={(e) => handleInputs(e, "setting")}
                />
                {corporateSettingErrorData.zoneManagerDashboardTitle && (
                  <div className="text-danger">
                    {corporateSettingErrorData.zoneManagerDashboardTitle}
                  </div>
                )}
              </div>

              <div className="col-md-7 mb-4">
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label sub-title">
                      Session Timeout <span className="text-danger">*</span>
                    </label>
                  </div>

                  <div className="col-md-12">
                    <div className="row align-items-center">
                      <div className="col-md">
                        <input
                          type="number"
                          className="form-control input-field-ui"
                          id=""
                          placeholder="000"
                          name="sessionTimeout"
                          value={corporateSettingData.sessionTimeout}
                          onChange={(e) => handleInputs(e, "setting")}
                        />
                      </div>
                      <div className="col-md-auto ps-0">
                        <label className="form-label mb-0">Minutes</label>
                      </div>
                    </div>
                    {corporateSettingErrorData.sessionTimeout && (
                      <div className="text-danger">
                        {corporateSettingErrorData.sessionTimeout}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-7 mb-4">
                <label className="form-label sub-title">
                  Corporate Email Domain <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="Enter Corporate Email Domain"
                  name="emailDomain"
                  value={corporateBasicData.emailDomain}
                  onChange={(e) => handleInputs(e, "basic")}
                />
                {corporateBasicErrorData.emailDomain && (
                  <div className="text-danger">
                    {corporateBasicErrorData.emailDomain}
                  </div>
                )}
              </div>

              {action === "create" && 
              <div className="col-md-7 mb-4">
                <label className="form-label sub-title">
                  Account Creation identifier{" "}
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select input-field-ui "
                  name="accountCreationIdentifier"
                  value={corporateSettingData.accountCreationIdentifier}
                  onChange={(e) => handleInputs(e, "setting")}
                >
                  <option value="">Select</option>
                  <option value="mobile">Mobile</option>
                </select>
                {corporateSettingErrorData.accountCreationIdentifier && (
                  <div className="text-danger">
                    {corporateSettingErrorData.accountCreationIdentifier}
                  </div>
                )}
              </div>
              }

              <div className="col-md-7 mb-4">
                <label className="form-label sub-title">
                  Reports Corporate identifier{" "}
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select input-field-ui "
                  name="reportUniqueIdentifier"
                  value={corporateSettingData.reportUniqueIdentifier}
                  onChange={(e) => handleInputs(e, "setting")}
                >
                  <option value="">Select</option>
                  <option value="email">Email</option>
                </select>
                {corporateSettingErrorData.reportUniqueIdentifier && (
                  <div className="text-danger">
                    {corporateSettingErrorData.reportUniqueIdentifier}
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn program-save-btn me-2"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>{" "}
                <button type="button" className="btn clear-btn">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddNewCorporate;
