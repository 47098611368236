import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';


function LeaderboardTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('All');

    const columns = [
        {
            name: 'Leader Board URL',
            selector: row => row.leaderBoardURL,
            sortable: true,
            cell: row => (
                <a href="#" dangerouslySetInnerHTML={{ __html: row.leaderBoardURL }}></a>
            ),
        },
        {
            name: 'Type of Challenge',
            selector: row => row.typeOfChallenge,
            sortable: true,
        },
        {
            name: 'Challenge Start Date',
            selector: row => row.challengeTime,
            sortable: true,
        },
        {
            name: 'Challenge End Date',
            selector: row => row.challengeTimeEnd,
            sortable: true,
        },
        {
            name: 'Participants Count',
            selector: row => row.participantsCount,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <div>
                    <button className="btn btn-Edit mx-2">
                        <i className="bi bi-pencil"></i>
                    </button> 
                    |
                    <button className="btn btn-Cancel mx-2" >
                        <i className="bi bi-trash"></i>
                    </button>
                </div>
            ),
        },
    ];

    const data = [
        {
            id: 1,
            leaderBoardURL: 'GOQii Elite Challenge',
            typeOfChallenge: 'Practice What We Preach',
            challengeTime: 'Jan 31, 2024',
            challengeTimeEnd: 'Jan 31, 2024',
            participantsCount: '257',
            status: 'Expired',
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedStatus('All');
    };

    const filteredData = data.filter(item => {
        const matchesSearchQuery =
            item.leaderBoardURL.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.challengeTime.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesStatus = selectedStatus === 'All' || item.status === selectedStatus;

        return matchesSearchQuery && matchesStatus;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Campaign Name or Schedule Time"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src={require("../../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Type of Challenge--</option>
                            <option value="Active">Active</option>
                            <option value="Expired">Expired</option>
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className="col-md-auto text-end pe-0"><Link className="btn btn-primary add-new-program-btn" to="/create-leaderboard">Create Leaderboard</Link></div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />

        </>
    );
}

export default LeaderboardTable;
